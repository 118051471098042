import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Grid,
  SettingsIcon,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { OpenInBrowser, HighlightOff, RecordVoiceOver, Contacts, PhoneInTalk } from '@mui/icons-material';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme) =>
  createStyles({
    openButton: {
      position:'fixed',
      bottom:'8px',
      left:'8px',
      zIndex:1202,
      backgroundColor: theme.palette.background.light,
      '&:hover': {
        backgroundColor: theme.palette.background.subtle,
      },
      color: theme.palette.primary.light,
      borderRadius:'4px',
      padding:'4px',
      transition: 'bottom 800ms',
      filter: 'drop-shadow(2px 4px 6px black)',
    },
    openButtonRed: {
      position:'fixed',
      bottom:'8px',
      left:'8px',
      zIndex:1202,
      backgroundColor: theme.palette.background.light,
      '&:hover': {
        backgroundColor: theme.palette.background.subtle,
      },
      borderRadius:'4px',
      padding:'4px',
      color: theme.palette.warning.main,
      transition: 'bottom 800ms',
      filter: 'drop-shadow(2px 4px 6px black)',
    },
    closeButton: {
      position:'fixed',
      bottom:'170px',
      left:'8px',
      zIndex:1203,
      filter: 'drop-shadow(2px 4px 6px black)',
      backgroundColor: theme.palette.background.light,
      '&:hover': {
        backgroundColor: theme.palette.background.subtle,
      },
      color: theme.palette.primary.light,
      borderRadius:'4px',
      padding:'4px',
      transition: 'bottom 800ms',
    },
    redButton: {
      color: theme.palette.warning.main
    },
    base: {
      backgroundColor: theme.palette.background.subtle,
      position: 'relative',
      borderTop: '1px solid ' + theme.palette.secondary.dark,
    }
  })
);

export default function BottomDrawer(props) {
  const roomState = useRoomState();
  const classes = useStyles();
  const paperWidth = (props.isChatOpen ? 'calc(100% - 304px)' : '100%');

  return <>
    {
      props.isAllowed &&
      <Tooltip title="Video Chat">
        <IconButton
          onClick={() => props.setIsOpen(!props.isVCOpen)}
          className={
            props.isVCOpen ?
              classes.closeButton
            :
              roomState === 'connected' ?
                classes.openButtonRed :
                classes.openButton
            }
          color="secondary"
          id="open_video_chat"
          disabled={!props.isAllowed && !props.isVCOpen}
          size="large">
          {
            props.isVCOpen ?
            <OpenInBrowser
              style={{transform:'rotate(180deg)'}}
              color="primary"
            />
            :
            roomState === 'connected' ?
              <RecordVoiceOver
                className={classes.redButton}
              /> :
              <PhoneInTalk color="primary" />
          }
        </IconButton>
      </Tooltip>
    }

    <Drawer
      open={props.isVCOpen}
      anchor='bottom'
      variant='persistent'
      transitionDuration={600}
      className={classes.base}
      PaperProps={{
        style: {
          paddingLeft: '36px',
          paddingBottom:'8px',
          height: '205px',
          flexDirection:'row',
          overflowX: 'auto',
          overflowY: 'hidden',
          zIndex: 1002,
          width: paperWidth,
          border: 'none',
          transition: 'width 600ms',
          borderTop: '1px solid rgba(31, 105, 137, 1)'
        }
      }}
    >
      <Grid container id="bottomDrawer" style={{height:'190px'}}>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Drawer>
  </>;
}
