import React, { useEffect, useState, useRef } from 'react';
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
  Button,
  Menu,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SpeakerIcon from '@mui/icons-material/Speaker';
import useDevices from '../../hooks/useDevices/useDevices';
import Tooltip from '@mui/material/Tooltip';

export interface OutputSelectProps {
  activeSinkId: string,
  setActiveSinkId: Function,
  cam_index: Number,
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  form: {
    maxWidth:'100%'
  }
}));

export default function AudioOutputSelect(props: OutputSelectProps) {
  const classes = useStyles();
  const audioOutAnchor = useRef();
  const [showOutSelect, setShowOutSelect] = useState(false);
  const [hasSinkId, setHasSinkId] = useState(false);
  const { audioOutputDevices } = useDevices();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === props.activeSinkId)?.label;

  useEffect(() => {
    if (audioOutputDevices.length > 1) {
      setHasSinkId(true);
    } else {
      setHasSinkId(false);
    }
  }, [audioOutputDevices]);

  const setSinkId = (e) => {
    props.setActiveSinkId(e.target.value as string);
    setShowOutSelect(false);
  }

  return <>
    {
      hasSinkId &&
      <>
        <Tooltip title="Audio Output">
          <Button
            onClick={e => setShowOutSelect(!showOutSelect)}
            color = 'secondary'
            variant = "contained"
            ref={audioOutAnchor}
            disabled={!hasSinkId}
            style={props.style}
          >
            <SpeakerIcon />
          </Button>
        </Tooltip>
        <Menu
          open={showOutSelect}
          anchorEl={audioOutAnchor.current}
          onClose={() => setShowOutSelect(false)}
        >
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
            Audio Output
            </Typography>

            {audioOutputDevices.length > 1 ? (
              <FormControl variant="standard" className={classes.form}>
                <Select
                  onChange={e => setSinkId(e)}
                  value={props.activeSinkId}
                  variant="outlined"
                >
                  {audioOutputDevices.map(device => (
                    <MenuItem value={device.deviceId} key={device.deviceId}>
                      {device.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <>
                <Typography variant="subtitle2">Audio Output</Typography>
                <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Menu>
    </>
  }
</>;
}
