import React, { useState, useEffect } from 'react';
import { styled, Theme } from '@mui/material/styles';
import Video from 'twilio-video';

import MenuBar from './MenuBar/MenuBar';
import MobileTopMenuBar from './MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './ReconnectingNotification/ReconnectingNotification';
import Room from './Room/Room';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import useRoomState from '../../hooks/useRoomState/useRoomState';

import Grid from '@mui/material/Grid';

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  width: '100%',
}));

export default function VC(props) {
  const roomState = useRoomState();
  const { room, isSharingScreen, toggleScreenShare, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();

  const [isAllowed, toggleAllowed] = useState(props.isAllowed);

  useEffect(() => {
    if (!isAllowed && roomState === 'connected') {
      if (isSharingScreen) {
        toggleScreenShare();
      }
      removeLocalAudioTrack();
      removeLocalVideoTrack();
      room.disconnect();
    }
  }, [isAllowed])

  useEffect(() => {
    toggleAllowed(props.isAllowed)
  }, [props])

  if (!Video.isSupported) {
    return (
      <Typography>
        We cannot enable video chat on your browser.  Please switch to a supported browser and try again.
        <br />
        <Link
          href="https://setstream.io/knowledge-base"
          target="_blank"
          rel="noopener"
          underline="hover">
          Support
        </Link>
      </Typography>
    );
  } else if (!isAllowed) {
    // Don't render VC if permission is denied
    return null;
  } else {
    return (

      <Grid container>
        {roomState === 'disconnected' ? (
          <PreJoinScreens
            nickName={props.nickName}
            updateNickName={props.updateNickName}
          />
        ) : (
          <Main>
            <ReconnectingNotification />
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </Main>
        )}
      </Grid>
    );
  }
}
