import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Typography,
Button,
} from '@mui/material/';
import formatExpiration from '../../../utils/format_expiration';
import useMaxViewers from '../Hooks/use_max_viewers';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
}));

export default function DisplayScheduledPasses(props) {
  const classes = useStyles();
  const maxViewers = useMaxViewers({
    activeSubs: props.activeSubs,
    activePasses: []
  });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        <Grid container>
          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No Scheduled Passes */}
            {
              props.scheduledPasses.length === 0 &&
              <Typography variant="subtitle1" className={props.cssClasses.alertText}>
                NO SCHEDULED PASSES
              </Typography>
            }

            {/* Has Scheduled Passes */}
            {
              props.scheduledPasses.length > 0 &&
              <Typography variant="subtitle2">
                {props.scheduledPasses[0].max_allowed_users} Viewers Begins<br />
                {formatExpiration(props.scheduledPasses[0].begins_on)}
              </Typography>
            }

            {
              props.scheduledPasses.length > 1 &&
              <Typography variant="subtitle2">
                {props.scheduledPasses.length - 1} Other Scheduled {
                  props.scheduledPasses.length > 2 ? 'Passes' : 'Pass'
                }
              </Typography>
            }
          </Grid>

          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No Scheduled Passes */}
            {
              !props.isBroadcastOnly &&
              props.scheduledPasses.length === 0 &&
              <Button
                onClick={() => props.handleOpen('schedule')}
                variant="contained"
                color="primary"
              >
                SCHEDULE PASS
              </Button>
            }

            {/* Has Scheduled Passes */}
            {
              !props.isBroadcastOnly &&
              props.scheduledPasses.length > 0 &&
              <Button
                onClick={() => props.handleOpen('schedule')}
                variant="contained"
                color="secondary"
              >
                MANAGE SCHEDULED
              </Button>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

}
