import React, { useState, useRef } from 'react';
import {
  TextField ,
  Menu,
  MenuItem,
  Grid,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { isMobile } from '../../utils';

const useStyles = makeStyles((theme) => ({
  chatInput: {
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    width: '246px',
    padding: (theme.spacing(2), '28px', theme.spacing(2), theme.spacing(2)),
    borderRadius: '4px',
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: '8px',
      left: '64px',
      width: 'calc(100vw - 72px)'
    }
  },
  emojiPicker: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      right: '4px',
      bottom: '12px'
    }
  }
}));

export default function ChatInput(props) {
  const classes = useStyles();
  const [inputText, updateInputText] = useState('');

  // Emoji State
  const inputRef = useRef();
  const emojiRef = useRef();
  const [pickEmoji, setPickEmoji] = useState(false);

  const sendChat = (e) => {
    props.onSend(e)
    updateInputText('')
  }

  const closePicker = () => {
    setPickEmoji(false);
    setTimeout(() => {
      inputRef.current.focus();
    }, 50)
  }

  const acceptEmoji = (emoji) => {
    let newText = inputText;
    newText += ' ' + emoji.native
    updateInputText(inputText + emoji.native);
    setPickEmoji(false);
    setTimeout(() => {
      inputRef.current.focus();
    }, 50)
  }

  return (
    <form onSubmit={sendChat}>

      <TextField
        variant="outlined"
        value={inputText}
        placeholder= 'Send a message'
        fullWidth
        name="message"
        autoComplete="off"
        onChange={e => {updateInputText(e.target.value)}}
        className={classes.chatInput}
        size="small"
        inputRef={inputRef}
      />

      {
        !isMobile &&
        <IconButton
          onClick={() => setPickEmoji(!pickEmoji)}
          className={classes.emojiPicker}
          ref={emojiRef}
          size="large">
          <EmojiEmotionsIcon />
        </IconButton>
      }

      {
        !isMobile &&
        <Menu
          anchorEl={emojiRef.current}
          open={pickEmoji}
          onClose={closePicker}
          variant='menu'
        >
          <Picker
            onSelect={emoji => acceptEmoji(emoji)}
            title=""
            theme="dark"
            showPreview={false}
            color="#47b969"
            emoji=""
            perLine={7}
          />
        </Menu>
      }

    </form>
  );
}
