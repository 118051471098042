import { ChannelAuthorizationRequestParams, ChannelAuthorizationCallback } from "pusher-js/types/src/core/auth/options"

// Custom Pusher Auth function
export default async function pusherAuth(data: ChannelAuthorizationRequestParams, callback: ChannelAuthorizationCallback, room: string, userUuid: string) {
  try {
    let fetchUrl = process.env.REACT_APP_API_URL + '/api/pusher/auth/' + room + '/' + userUuid
    let fetchData = {
      socket_id: data.socketId,
      channel_name: data.channelName
    }
    let fetchOpts: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(fetchData)
    }
    let res = await fetch(fetchUrl ,fetchOpts)
    let response = await res.json()
    if (res.ok) {
      if (response.auth) {
        callback(null, response)
      } else {
        console.error("Pusher Auth Failed", response)
        callback(new Error("No Channels Auth Token Returned"), null)
      }
    } else {
      console.error("Pusher Auth Refused", res.status, response)
      callback(new Error("Authenticating Channels Error " + res.status), null)
    }
  } catch (error: any) {
      console.error("Network Error Authorizing Pusher", error)
      callback(new Error("Network Error Authorizing Channels"), null)
  }
}