import React, { useState } from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMainParticipant from '../../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useDominantSpeaker from '../../../hooks/useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useMutedParticipants from '../VideoProvider/useMutedParticipants/useMutedParticipants';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import MainParticipant from '../MainParticipant/MainParticipant';
import MobileParticipantInfo from '../ParticipantInfo/MobileParticipantInfo';
import { useAppState } from '../../../state';
import { isMobile } from '../../../utils';

import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import ContactsIcon from '@mui/icons-material/Contacts';
import ChatInput from './chat_input';
import ChatBubble from './chat_bubble';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    participantList: {
      alignContent: 'flex-end',
      height: '100%',
      paddingBottom: '74px',
      paddingRight: '12px',
      flexDirection: 'column'
    }
  })
);

export default function ParticipantList() {
  const classes = useStyles();

  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const dominantSpeaker = useDominantSpeaker();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const [mutedParticipants, setMutedParticipants] = useMutedParticipants();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const { isAudioOnly } = useAppState();

  const [drawerOpen, toggleDrawerOpen] = useState(false);

  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <Grid container id="participant-list" style={{flexWrap:'nowrap'}}>

      <Grid item style={{display:"flex", flexDirection:"row-reverse"}}>

        {
          !drawerOpen &&
          <IconButton
            onClick={() => toggleDrawerOpen(!drawerOpen)}
            color="secondary"
            style={{
              position:'fixed',
              bottom:'12px',
              right:'12px',
              zIndex:1199,
              backgroundColor:'rgba(58,58,58,.9)'
            }}
            size="large">
            <ContactsIcon />
          </IconButton>
        }

        <Drawer
          open={drawerOpen}
          onClose={() => toggleDrawerOpen(!drawerOpen)}
          variant="temporary"
          anchor="right"
          PaperProps = {{style:{backgroundColor:'rgba(0,0,0,0.7)', width:'200px', overflow:'hidden',}}}
        >
          <IconButton
            onClick={() => toggleDrawerOpen(!drawerOpen)}
            color="secondary"
            style={{
              position:'fixed',
              bottom:'12px',
              right:'12px',
              zIndex:1199,
              backgroundColor:'rgba(58,58,58,.9)'
            }}
            size="large">
            <ExitToAppIcon />
          </IconButton>
          <Grid container className={classes.participantList}>
            <Grid item style={{flex:1}}></Grid>
            {participants.map((participant, i) => {
              const isSelected = participant === selectedParticipant;
              const isLocalParticipant = participant === localParticipant;

              return (
                <MobileParticipantInfo
                  participant={participant}
                  isSelected={isSelected}
                  isLocalParticipant={isLocalParticipant}
                />
              );
            })}
          </Grid>

        </Drawer>

      </Grid>

    </Grid>
  );
}
