import useSubscriptionRules from '../useSubscriptionRules/useSubscriptionRules';
import { useAppState } from '../../state';

export default function useAudioOnly() {

  const { isAudioOnly, setIsAudioOnly } = useAppState();
  const updateSubRules = useSubscriptionRules();

  const toggleAudioOnly = () => {
    updateSubRules(!isAudioOnly ? 'audio-only' : 'audio-video', null)
    .then(response => {
      setIsAudioOnly(!isAudioOnly);
    })
    .catch(err => {
      console.error('Could not update subscriptions - Error - ', err.message);
    });
  }

  return [isAudioOnly, toggleAudioOnly]
}
