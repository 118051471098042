import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Menu,
MenuItem,
Dialog,
DialogTitle,
DialogContent,
DialogActions,
Typography,
TextField,
Button,
Link,
IconButton,
Paper,
Backdrop,
CircularProgress,
} from '@mui/material/';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SettingsIcon from '@mui/icons-material/Settings';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';
// import Cookies from 'js-cookie';
import useDashboardAccount from '../Context/useDashboardAccount';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid ' + theme.palette.background.light
    }
  }
}));

export default function ProjectListItem(props) {
  const classes = useStyles();
  const anchorEl = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { getAccountData, customer_uuid } = useDashboardAccount();

  // State
  // Display / hide
  const [inProgress, setInProgress] = useState(false);
  const [showSettings, setshowSettings] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showRename, setShowRename] = useState(false);

  // Text to show
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogText, setDialogText] = useState();

  // Project Data
  const [newProjectName, setNewProjectName] = useState();
  const [projectAction, setProjectAction] = useState();

  // Available options
  const project_settings = [
    'rename_project',
    'delete_chat_history',
    'archive_project'
  ]

  // Copy text to clipboard
  const copy_text = (event, title, text) => {
    copy(text);
    enqueueSnackbar(title + ' Copied', { variant: 'info' });
  }

  const confirm_setting = (option) => {
    switch (option) {
      case 'rename_project':
        setProjectAction('rename_project')
        setDialogTitle('RENAME PROJECT');
        setDialogText('Enter a new name for the project here.');
        setShowRename(true);
        setShowDialog(true);
        break;
      case 'delete_chat_history':
        setProjectAction('delete_chat_history')
        setDialogTitle('DELETE CHAT HISTORY');
        setDialogText('You are about to permanently erase the chat history for this project.  This cannot be undone.');
        setShowRename(false);
        setShowDialog(true);
        break;
      case 'archive_project':
        setProjectAction('archive_project');
        setDialogTitle('ARCHIVE PROJECT');
        setDialogText('You are about to archive this project.  Access to this project will not be availabe unless it is reactivated.');
        setShowRename(false);
        setShowDialog(true);
        break;
      default:
        setProjectAction('');
        setDialogTitle('Error');
        setDialogText('Could not complete the requested action.  Please contact support.');
        setShowRename(false);
        setShowDialog(true);
    }
  }

  const do_setting = async (e, proj_id) => {
    e.preventDefault();
    setInProgress(true);

    let fetchData = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: "include",
      body: JSON.stringify({
        project_id: proj_id,
        new_project_name: newProjectName
      })
    }
    try {
      let res = await fetch(
        process.env.REACT_APP_API_URL + '/api/producer_update_session/' + projectAction + '/' + customer_uuid,
        fetchData
      )
      if (res.ok) {
        let response = await res.json();
        // Update list
        getAccountData();
        enqueueSnackbar(dialogTitle + ' complete');
      } else {
        console.error('Error ', res.statusText, res.status);
      }

    } catch (e) {
      console.error('Could not update setting ', e);
    } finally {
      setInProgress(false);
      setShowDialog(false);
      setshowSettings(false);
    }
  }

  const project_login = async (project) => {
    // let cookie = Cookies.get(project.publisher_uuid);
    // if (!cookie) {
      let postData = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          publisher_uuid: project.publisher_uuid,
          publisher_password: project.publisher_password
        })
      }
      try {
        let res = await fetch(process.env.REACT_APP_API_URL + '/api/projlogin', postData);
        if (res.ok) {
          // let response = await res.json();
        }
      } catch (err) {
        enqueueSnackbar('There was an error logging you in.  Please use the password next to the link on this page.', {variant: 'error'});
        console.error('Could not login project - Error - ', err);
      } finally {

      }
    // }
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={2} sm={1} align="center" className={props.cssClasses.tableCell}>

        {/* Settings */}
        <IconButton onClick={e => setshowSettings(true)} ref={anchorEl} size="large">
          <SettingsIcon />
        </IconButton>
        <Menu
          id={props.project._id + "-settings"}
          anchorEl = {anchorEl.current}
          keepMounted
          open={showSettings}
          onClose={e => setshowSettings(false)}
          PaperProps={{
            className:props.cssClasses.popoverMenu,
            style: {
              minWidth: '45ch',
            },
          }}
          MenuListProps={{
            style: {

            }
          }}
        >
          <Grid container align="center" className={props.cssClasses.tableHeader}>
            {props.project.project_name} (ID: {props.project._id})
          </Grid>

          <Grid container className={props.cssClasses.settingsContainer}>

              <Grid item xs={12}>
                <MenuItem
                  onClick={e => confirm_setting('rename_project')}
                >
                  <Grid container>
                    <Grid item style={{paddingRight: '8px'}}>
                      <EditIcon />
                    </Grid>
                    <Grid item>
                      Rename Project
                    </Grid>
                  </Grid>
                </MenuItem>

                <MenuItem
                  onClick={e => confirm_setting('delete_chat_history')}
                >
                  <Grid container>
                    <Grid item style={{paddingRight: '8px'}}>
                      <DeleteForeverIcon />
                    </Grid>
                    <Grid item>
                      Delete Chat History
                    </Grid>
                  </Grid>
                </MenuItem>

                <MenuItem
                  onClick={e => confirm_setting('archive_project')}
                >
                  <Grid container>
                    <Grid item style={{paddingRight: '8px'}}>
                      <BackspaceIcon />
                    </Grid>
                    <Grid item>
                      Archive Project
                    </Grid>
                  </Grid>
                </MenuItem>

                <Dialog
                  open={showDialog}
                  onClose={e => setShowDialog(false)}
                >
                  <DialogTitle className={props.cssClasses.dtitle}>
                    <Typography>
                      {dialogTitle}
                    </Typography>
                  </DialogTitle>

                  <DialogContent className={props.cssClasses.dcontent}>
                    <Paper elevation={0} className={props.cssClasses.padding}>
                      <Typography color="black">
                        {dialogText}
                      </Typography>
                      <TextField
                        variant="outlined"
                        disabled={!showRename}
                        style={{'display': !showRename ? 'none' : 'block'}}
                        value={newProjectName}
                        autoFocus
                        placeholder= 'rename_project'
                        fullWidth
                        name="rename_project"
                        autoComplete="off"
                        onChange={e => setNewProjectName(e.target.value)}
                      />
                    </Paper>
                  </DialogContent>

                  <DialogActions className={props.cssClasses.dactions}>
                    <Button
                      id = 'cancel'
                      onClick = {e => setShowDialog(false)}
                      variant="contained"
                      className={props.cssClasses.selectButton}
                    >
                      Cancel
                    </Button>
                    <Button
                      id = {props.project._id}
                      onClick = {e => do_setting(e, props.project._id)}
                      variant="contained"
                      color="primary"
                      className={props.cssClasses.selectButton}
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>

          </Grid>
        </Menu>
      </Grid>

      <Grid item xs={10} sm={3} className={props.cssClasses.tableCell}>
        <Typography>{props.project.project_name}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} className={props.cssClasses.tableCell}>
        <Link
          href={props.project.project_url}
          target="_blank"
          onClick={e => project_login(props.project)}
          underline="hover">
          {props.project.project_url}
        </Link>
        <IconButton
          className={props.cssClasses.copyIcon}
          onClick={e => copy_text(e, 'Link', props.project.project_url)}
          size="large">
          <FileCopyIcon />
        </IconButton>
      </Grid>

      <Grid item xs={12} sm={2} className={props.cssClasses.tableCell}>
        <Typography style={{fontWeight:'bold', display:'inline'}}>{props.project.publisher_password}</Typography>
        <IconButton
          className={props.cssClasses.copyIcon}
          onClick={e => copy_text(e, 'Password', props.project.publisher_password)}
          size="large">
          <FileCopyIcon />
        </IconButton>
      </Grid>

      <Backdrop open={inProgress} style={{zIndex: '2001'}}>
        <CircularProgress color="primary" variant="indeterminate" />
      </Backdrop>
    </Grid>
  );

}
