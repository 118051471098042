import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
FormControl,
FormControlLabel,
Input,
InputLabel,
Select,
MenuItem,
Button,
Checkbox,
Popover,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import VideoControls from './video_controls';
import useCamera from '../../CameraProvider/useCamera';
import { videoBitrates } from '../../CameraProvider/Settings/video_bitrates';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(4),
    border: '2px solid ' + theme.palette.secondary.main,
    "&:hover": {
      border: '2px solid ' + theme.palette.primary.main,
    },
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(3),
  },
  section: {
    margin: 'auto',
  },
  sectionTitle: {
    paddingRight: '12px',
    textDecoration: 'underline',
    color: theme.palette.text.secondary,
  },
  title: {
    color: theme.palette.text.secondary,
    borderBottom: "1px solid " + theme.palette.secondary.main
  }
}));

interface Props {

}

export default function Video(props: Props) {
  // Hooks
  const {
    enabled,
    label,
    videoInputDevices,
    videoDeviceId,
    videoInputPermission,
    videoCodecs,
    videoCodec,
    updateVideoCodec,
    videoResolutions,
    videoWidth,
    updateVideoWdith,
    videoBitrate,
    updateVideoBitrate,
    rtcPeerConnection,
    cssClasses
  } = useCamera();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  // State
  const [showSettings, setShowSettings] = useState<boolean>(false);

  // Refs
  const containerRef = useRef(null);

  // Effects

  // Actions

  // Setting dialog controls
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  }
  const closeSettings = () => {
    setShowSettings(false);
  }

  // Display Renderers
  const videoInputDisplay = () => {
    if (videoInputPermission === "denied") {
      return (
        <Grid container>
          <Grid item>
            <Typography
              align="left"
            >
              Permission Denied
            </Typography>
          </Grid>
          <Grid item>
            <ErrorIcon
              color="error"
            />
          </Grid>
        </Grid>
      )
    } else if (
      videoDeviceId === "" && 
      videoInputDevices.find(vd => vd?.deviceId === videoDeviceId)?.label === ""
    ) {
      return (
        <Grid container>
          <Grid item>
            <Typography
              align="left"
            >
              No Video
            </Typography>
          </Grid>
          <Grid item>
            <ErrorIcon
              color="error"
            />
          </Grid>
        </Grid>
      )
    } else {
      return (
      <Typography
        align="left"
      >
        {videoInputDevices.find(vd => vd?.deviceId === videoDeviceId)?.label}
      </Typography>
      )
    }
  }

  // Render
  return (
    <Grid 
      container 
      className={classes.container}
      onClick={toggleSettings}
      ref={containerRef}
    >
      <Grid
        item
        xs={7}
        md={8}
        xl={10}
        className={classes.section}
      >
        <Typography
          align="left"
          variant="subtitle2"
          className={classes.sectionTitle}
        >
          Video Input:
        </Typography>
          { videoInputDisplay() }
      </Grid>

      <Grid 
        item 
        xs={5} 
        md={4} 
        xl={2} 
        className={classes.section}
      >
        <Typography
          align="right"
          display="block"
          variant="subtitle2"
        >
          {
            String(videoCodec).toUpperCase()
          }
        </Typography>
        <Typography
          align="right"
          display="block"
          variant="subtitle2"
          >
            {
              videoBitrates?.find(br => br.bitrate === videoBitrate)?.label
            }
          </Typography>
        <Typography
          align="right"
          display="block"
          variant="subtitle2"
        >
          {
            videoResolutions?.find(res => res.width === videoWidth)?.label
          }
        </Typography>
      </Grid>

      <Popover
        onClose={closeSettings}
        open={showSettings}
        anchorEl={containerRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      >
        <VideoControls />
      </Popover>

    </Grid>
  );

}
