import React, { useEffect, useState } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Typography,
Button,
} from '@mui/material/';
import formatExpiration from '../../../utils/format_expiration';
import useMaxViewers from '../Hooks/use_max_viewers';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
}));

export default function DisplayActivePasses(props) {
  const classes = useStyles();
  const maxViewers = useMaxViewers({
    activeSubs: props.activeSubs,
    activePasses: props.activePasses
  });
  const passViewers = useMaxViewers({
    activeSubs: [],
    activePasses: props.activePasses
  });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>

        <Grid container>
          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No active pass */}
            {
              props.activeSubs.length === 0 &&
              props.activePasses.length === 0 &&
              <Typography variant="subtitle1" className={props.cssClasses.alertText}>
                NO ACTIVE PASS
              </Typography>
            }

            {/* Has Active Pass */}
            {
              (props.activeSubs.length > 0 ||
                props.activePasses.length > 0) &&
              <Typography variant="h6" className={props.cssClasses.alertText}>
                {maxViewers} Viewers
              </Typography>
            }
            {
              props.activePasses.length > 0 &&
              <Typography variant="subtitle2">
                {passViewers} Viewers Until {formatExpiration(props.activePasses[0].expires_on)}
              </Typography>
            }
            {
              props.activeSubs.length > 0 &&
              <Typography variant="subtitle2">
                {
                  props.activeSubs.length > 1 ?
                  props.activeSubs.length + ' Subscriptions' :
                  props.activeSubs[0].max_allowed_users + ' Viewer Subscription'
                }
              </Typography>
            }
          </Grid>

          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No Active Pass */}
            {
              !props.isBroadcastOnly &&
              props.activeSubs.length === 0 &&
              props.activePasses.length === 0 &&
              <Button
                onClick={() => props.handleOpen('activate')}
                variant="contained"
                color="primary"
              >
                ACTIVATE PASS
              </Button>
            }

            {/* Has Active Pass */}
            {
              !props.isBroadcastOnly &&
              (props.activeSubs.length > 0 ||
              props.activePasses.length > 0) &&
              <Button
                onClick={() => props.handleOpen('activate')}
                variant="contained"
                color="secondary"
              >
                MANAGE ACCESS
              </Button>
            }
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );

}
