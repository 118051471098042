import React from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
// import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../../state';
// import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';

// Material UI
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const useStyles = makeStyles((theme: Theme) => ({

}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (

    <Grid container>

      <Grid item xs={12}>
        {props.children}
      </Grid>

      {
        props.subContent &&
        <Grid item xs={12}>
          {props.subContent}
        </Grid>
      }

    </Grid>

  );
};

export default IntroContainer;
