import React from 'react';
import { FormControl, MenuItem, Typography, Select, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppState } from '../../../../../state';
import useDevices from '../../../../../hooks/useDevices/useDevices';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  form: {
    maxWidth:'100%'
  }
}));

export default function AudioOutputList() {
  const classes = useStyles();
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={4}>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2" gutterBottom>
          Audio Output
          </Typography>
          
          {audioOutputDevices.length > 1 ? (
            <FormControl variant="standard" className={classes.form}>
              <Select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId} variant="outlined">
                {audioOutputDevices.map(device => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <>
              <Typography variant="subtitle2">Audio Output</Typography>
              <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
