import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import IconButton from '@mui/material/IconButton';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../../icons/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from '../PinIcon/PinIcon';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import Typography from '@mui/material/Typography';

import useIsTrackSwitchedOff from '../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import { useAppState } from '../../../state';
import MuteParticipant from '../MuteParticipant/MuteParticipant';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      overflow: 'hidden',
      marginBottom: '2em',
      '& video': {
        filter: 'none',
      },
      borderRadius: '4px',
      paddingTop: `calc(${(9 / 16) * 100}% - ${theme.participantBorderWidth}px)`,
      background: 'black',
    },
    innerContainer: {
      position: 'absolute',
      top: '0',
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems:'center'
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
      borderRadius: '8px;'
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.3)',
      color: 'white',
      padding: '0.18em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderRadius: '4px 4px 0 0'
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      whiteSpace: 'nowrap'
    },
    infoRowTop: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 0,
      right: 0,
    },
    muteParticipantContainer: {
      display:'flex',
      width: '28px',
      height: '28px',
      position: 'absolute',
      right: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      borderRadius: '8px'
    },
    pinParticipantContainer: {
      display:'flex',
      width: '28px',
      height: '28px',
      position: 'absolute',
      left: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      borderRadius: '8px'
    },
    typeography: {
      color: 'white',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    }
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  setSelectedParticipant?: () => void;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  setSelectedParticipant,
  children,
  isLocalParticipant,
  hideParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const { isAudioOnly } = useAppState();

  const classes = useStyles();

  return (
    <div
      className={classes.container}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>

        {
          !isLocalParticipant &&
          !isMobile &&
          <div className={classes.pinParticipantContainer}>
            <IconButton
              onClick={() => setSelectedParticipant(participant)}
              style={{padding:0}}
              size="large">
              <PinIcon
                isPinned={isSelected}
              />
            </IconButton>
          </div>
        }

        {
          !isLocalParticipant &&
          <div className={classes.muteParticipantContainer}>
            <MuteParticipant participant={participant} />
          </div>
        }

        <div className={classes.infoRowBottom}>

          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Typography variant="body1" className={classes.typeography} component="span">
              {!isLocalParticipant && participant.identity}
              {isLocalParticipant && ' (You)'}
            </Typography>
          </span>
        </div>
      </div>
      <div className={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className={classes.avatarContainer}>
            <AvatarIcon />
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Typography variant="body1" className={classes.typeography}>
              Reconnecting...
            </Typography>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
