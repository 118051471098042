import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionDialog from './DeviceSelectionDialog/DeviceSelectionDialog';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import NickNameScreen from './NickNameScreen/NickNameScreen';
import RoomConnectingStep from './RoomConnectingStep';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import logToServer from '../../../utils/log_to_server';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
  RoomConnectingStep,
}

export default function PreJoinScreens(props) {
  const { 
    getAudioAndVideoTracks,
    roomName,
    userUuid
   } = useVideoContext();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.dir(error);
        setMediaError(error);
        console.log('Error acquiring local media:');
        logToServer({
          section: 'Twilio',
          action: 'getUserMedia Error',
          page: window.location.href,
          message: error?.message,
          eName: error?.name,
          eStack: error?.stack
        })
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(Steps.deviceSelectionStep);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.roomNameStep && (
        <NickNameScreen
          roomName={roomName}
          userUuid={userUuid}
          nickName={props.nickName}
          updateNickName={props.updateNickName}
          handleSubmit={handleSubmit}
        />

      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionDialog
          roomName={roomName}
          userUuid={userUuid}
          nickName={props.nickName}
          name={props.nickName}
          setStep={setStep}
        />
      )}

      {step === Steps.RoomConnectingStep && (
        <RoomConnectingStep />
      )}
    </IntroContainer>
  );
}
