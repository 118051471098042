import React from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import useAudioOnly from '../../../../hooks/useAudioOnly/useAudioOnly';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    startIcon: {
      marginLeft: '-4px',
      marginRight: '6px',
      [theme.breakpoints.down('lg')]: {
        margin:0
      },
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const [isAudioOnly, toggleAudioOnly] = useAudioOnly();

  return (
    <Button
      className={props.className}
      classes={{startIcon: classes.startIcon}}
      onClick={toggleAudioOnly}
      disabled={props.disabled}
      startIcon={isAudioOnly ? <VideocamOffIcon /> : <VideocamIcon />}
      data-cy-audio-toggle
      variant='contained'
    >
      {isAudioOnly ? 'Enable Peer Video' : 'Reduce Bandwidth'}
    </Button>
  );
}
