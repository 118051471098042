import React, { useEffect, useState } from 'react';
import { createStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
Switch,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import { CameraProvider } from './CameraProvider/CameraProvider';
import Connection from './Controls/Connection/connection_controls';
import Video from './Controls/Video/video';
import Audio from './Controls/Audio/audio';
import BroadcastSource from './Controls/Sources/broadcast_source';
import Preview from './Preview/preview';
import Status from './Status/status';
import Stats from './Stats/stats';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  },
  A: {
    paddingLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.cameras.A
  },
  B: {
    paddingLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.cameras.B
  },
  C: {
    paddingLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.cameras.C
  },
  D: {
    paddingLeft: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.cameras.D
  },
  label: {
    paddingRight: theme.spacing(8),
    display: 'flex'
  },
  controls: {
    margin: 'auto'
  },
  preview: {
    margin: 'auto'
  },
  sources: {
    paddingTop: theme.spacing(2)
  },
  status: {
    textAlign: 'center',
    display: 'flex'
  }
}));

interface Props {
  label: string,
  enabled: boolean,
  color: string,
  cssClasses: any,
}

export default function Camera(props: Props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [enabled, setEnabled] = useState<boolean>(true)

  // Effects

  // Actions

  // Render
  return (
    <CameraProvider
      {...props}
    >
      <Grid 
        container 
        className={classes.container}
      >

        {/* Desktop */}
        <Hidden mdDown>
          <Grid 
            item 
            xs={12}
            className={classes[props.label]}
          >
            <Grid 
              container
            >
              <Grid>
                <Switch
                  checked={enabled}
                  onChange={() => setEnabled(!enabled)}
                  color="primary"
                />
              </Grid>

              <Grid
                className={classes.label}
                item
                alignItems="center"
              >
                <Typography
                  variant='h5'
                >
                  {props.label}
                </Typography>
              </Grid>

              <Grid
                className={classes.sources}
              >
                <BroadcastSource
                  enabled={enabled}
                />
              </Grid>

              <Grid
                item
                xs={true}
                alignItems="center"
                className={classes.status}
              >
                <Status 
                enabled={enabled}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} className={props.cssClasses.tableCell}>
            {
              enabled &&
              <Grid container>
                <Grid 
                  item 
                  xs={12} 
                  sm={6} 
                  md={4}
                  className={classes.controls}
                >
                  <Connection />
                  <Video />
                  <Audio />
                  <Stats />
                </Grid>

                <Grid 
                  item 
                  xs={12} 
                  sm={6} 
                  md={8}
                  className={classes.preview}
                >
                  <Preview />
                </Grid>
              </Grid>
            }

          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>
          <Grid item xs={12} className={props.cssClasses.tableHeader}>

          </Grid>
          <Grid item xs={12} className={props.cssClasses.tableCell}>

          </Grid>
        </Hidden>

      </Grid>
    </CameraProvider>
  );

}
