import React from "react";
import { styled } from '@mui/styles';
import Card from '@mui/material/Card';

const Squared = styled(Card)((props) => {
  return ({
    position:'relative',
    margin:'0 auto',
    zIndex: 5,
    width:'150px',
    height: (props.isAudioOnly ? '150px' : '150px'),
    overflow:'hidden',
    borderRadius: '12px',
    border: (props.border ?
    '3px solid ' + props.theme.palette.primary.main :
    '1px solid #333333')
  })
});

export default function SquareCard(props) {

  return (
    <Squared {...props}>
      {props.children}
    </Squared>
  )
}
