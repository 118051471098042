import React from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMainParticipant from '../../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useDominantSpeaker from '../../../hooks/useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useMutedParticipants from '../VideoProvider/useMutedParticipants/useMutedParticipants';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import MainParticipant from '../MainParticipant/MainParticipant';
import MobileParticipantList from './MobileParticipantList';
import { isMobile } from '../../../utils';

import Hidden from '@mui/material/Hidden';
import SquareCard from '../../Elements/SquareCard';
import Grid from '@mui/material/Grid';
import { useAppState } from '../../../state';

export default function ParticipantList() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const dominantSpeaker = useDominantSpeaker();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const [mutedParticipants, setMutedParticipants] = useMutedParticipants();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const { isAudioOnly } = useAppState();

  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  // if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

  return (
    <Grid container id="participant-list" style={{flexWrap:'nowrap', overflowX:'scroll'}}>

      <Grid item style={{display:"flex", flexDirection:"row-reverse"}}>

        {participants.map((participant, i) => {
          const isSelected = participant === selectedParticipant;
          let hideParticipant = false;
            // participant === mainParticipant &&
            // participant !== screenShareParticipant;
            // !isSelected;

            // Only show active speaker on mobile
            if (isMobile) {hideParticipant = true}

          return hideParticipant ? (
            <Participant
              key={participant.sid}
              participant={participant}
              isSelected={participant === selectedParticipant}
              setSelectedParticipant={setSelectedParticipant}
              videoOnly={mutedParticipants.includes(participant)}
              hideParticipant={hideParticipant}
            />
          ) : (
            <SquareCard
              key={participant.sid + 'card'}
              border={participant === dominantSpeaker}
              isAudioOnly={isAudioOnly}
              id="participantList"
            >
              <Participant
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                setSelectedParticipant={setSelectedParticipant}
                onClick={() => setSelectedParticipant(participant)}
                videoOnly={mutedParticipants.includes(participant)}
                hideParticipant={hideParticipant}
              />
            </SquareCard>
          );
        })}
      </Grid>

      <Grid item style={{flex:1}}>
      </Grid>

      <Grid item>

        <SquareCard border={false}>
          <Participant
            participant={localParticipant}
            isLocalParticipant={true}
          />
        </SquareCard>

      </Grid>

      {/* MOBILE */}
      <Hidden mdUp>
        <MobileParticipantList />
      </Hidden>

    </Grid>
  );
}
