export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 30
};

export const DEFAULT_AUDIO_CONSTRAINTS: MediaStreamConstraints['audio'] = {
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true
};

export const DEFAULT_SCREEN_CONSTRAINTS: MediaStreamConstraints['screen'] = {
  width: 1920,
  height: 1080,
  frameRate: 30
}


// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'ssvc-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'ssvc-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'ssvc-selectedVideoInput';
