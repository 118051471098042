import React from 'react';
import AudioLevelIndicator from '../../../AudioLevelIndicator/AudioLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import { makeStyles } from '@mui/styles';
import { FormControl, MenuItem, Typography, Select, Grid } from '@mui/material';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../../../constants';
import useDevices from '../../../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../../hooks/useVideoContext/useVideoContext';
import ToggleAudioButton from '../../../Buttons/ToggleAudioButton/ToggleAudioButton';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  form: {
    maxWidth:'100%'
  },
  joinButtons: {
    width:'150px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    marginTop:'12px',
    marginBottom:'12px',
    marginLeft:'auto',
    marginRight:'auto',
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(75,75,75,.85)',
    '&:hover': {
      backgroundColor: 'rgba(125,125,125,.85)',
    },
  },
}));

export default function AudioInputList({ disableButtons }: Boolean) {
  const classes = useStyles();

  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div className={classes.container}>

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <ToggleAudioButton disabled={disableButtons} className={classes.joinButtons} />
        </Grid>

        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2" gutterBottom>
          Audio Input
          </Typography>

          {audioInputDevices.length > 1 ? (
            <FormControl variant="standard" className={classes.form}>
              <Select
                onChange={e => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
              >
                {audioInputDevices.map(device => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography style={{display:"inline"}}>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
