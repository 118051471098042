import React from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import Grid from '@mui/material/Grid';
import SquareCard from '../../Elements/SquareCard';
import Hidden from '@mui/material/Hidden';
import { isMobile } from '../../../utils';
import { useAppState } from '../../../state';

export default function Room() {
  const { isAudioOnly } = useAppState();

  return (
    <Grid
      container
      id="roomContainer"
      justifyContent={isMobile ? "center" : "flex-end"}
      style={{width:'100%', padding: '4px', height:'168px'}}
    >

      <Grid item style={{overflow:'hidden', height:'168px'}}>
        <ParticipantList />
      </Grid>

      {
        !isAudioOnly &&
        <Hidden mdUp>
          <Grid item>
            <SquareCard
              id="mobileMainParticipant"
              border={false}
              isAudioOnly={isAudioOnly}
              style={{borderLeft:'2px solid #2F2F2F'}}
            >
              <MainParticipant />
            </SquareCard>
          </Grid>
        </Hidden>
      }

    </Grid>
  );
}
