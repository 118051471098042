import React, { useEffect, useState } from 'react';
import { createStyles} from '@mui/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
Backdrop,
CircularProgress,
Card,
CardMedia
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import Camera from '../Camera/camera';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(2),
  }
}));

interface Props {
  isActive: boolean,
  tab: boolean,
  enabledCameras: number,
  cssClasses: any,
  isVCOpen: boolean,
  isChatOpen: boolean,
  publisher_uuid: string,
  is_broadcast_only: string,
}

export default function Broadcast(props: Props) {
  // Hooks
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [aEnabled, setAEnabled] = useState<boolean>(true);
  const [bEnabled, setBEnabled] = useState<boolean>(true);
  const [cEnabled, setCEnabled] = useState<boolean>(true);
  const [dEnabled, setDEnabled] = useState<boolean>(true);

  // const [cameras, setCameras] = useState([
  //   {
  //     label: 'A',
  //     enabled: true,
  //     color: 'rgba(226, 29, 29, .7)',
  //   },
  //   // {
  //   //   label: 'B',
  //   //   enabled: false,
  //   //   open: false,
  //   //   anchorEl: null,
  //   //   color: 'rgba(50, 171, 223, .7)',
  //   //   selectedVideoDevice: '1',
  //   //   selectedAudioDevice: '1',
  //   //   selectedQuality: 2,
  //   //   selectedCodec: 'vp8',
  //   //   selectedEchoCancellation: false,
  //   //   video_display: null,
  //   //   videoSource: React.createRef(),
  //   //   videoStream: new MediaStream(),
  //   //   rtcStreamName: null,
  //   //   rtcConnection: null,
  //   //   isConnected: false
  //   // },
  //   // {
  //   //   label: 'C',
  //   //   enabled: false,
  //   //   open: false,
  //   //   anchorEl: null,
  //   //   color: 'rgba(226, 226, 29, .7)',
  //   //   selectedVideoDevice: '1',
  //   //   selectedAudioDevice: '1',
  //   //   selectedQuality: 2,
  //   //   selectedCodec: 'vp8',
  //   //   selectedEchoCancellation: false,
  //   //   video_display: null,
  //   //   videoSource: React.createRef(),
  //   //   videoStream: new MediaStream(),
  //   //   rtcStreamName: null,
  //   //   rtcConnection: null,
  //   //   isConnected: false
  //   // },
  //   // {
  //   //   label: 'D',
  //   //   enabled: false,
  //   //   open: false,
  //   //   anchorEl: null,
  //   //   color: 'rgba(71, 185, 105, .7)',
  //   //   selectedVideoDevice: '1',
  //   //   selectedAudioDevice: '1',
  //   //   selectedQuality: 2,
  //   //   selectedCodec: 'vp8',
  //   //   selectedEchoCancellation: false,
  //   //   video_display: null,
  //   //   videoSource: React.createRef(),
  //   //   videoStream: new MediaStream(),
  //   //   rtcStreamName: null,
  //   //   rtcConnection: null,
  //   //   isConnected: false
  //   // }
  // ]);
  // const [mcDirUrl, setMcDirUrl] = useState<string>();
  // const [mcDirToken, setMcDirToken] = useState<string>();
  // const [mcStreams, setMcStreams] = useState<[string]>();

  // // Effects
  // useEffect(() => {
  //   getPublishAuth();

  //   return () => {
  //   };
  // }, []);

  // Actions
  // const getPublishAuth = async () => {
  //   try {
      
  //     let url = process.env.REACT_APP_API_URL + 
  //       '/api/web/' + 
  //       (props.isBroadcastOnly ? 'Broadcast/' : 'Project/') +
  //       props.publisherUuid;
  //     let opts = {
  //       method: "GET",
  //       credentials: "include"
  //     }
  //     let response = await fetch(url, opts);
  //     if (response.ok) {
  //       let res = await response.json();
  //       switch (res.type) {
  //         case "inactive":
  //           // TODO: 
  //           break;
          
  //         case "error":
  //           // TODO:
  //           break;
          
  //         case "success":
  //           if (res.token) {
  //             setMcDirToken(res.token);
  //           }
  //           if (res.streams) {
  //             setMcStreams(res.streams);
  //           }
  //           if (res.mcDirUrl) {
  //             setMcDirUrl(res.mcDirUrl)
  //           }
  //           break;
        
  //         default:
  //           // TODO:
  //           break;
  //       }

  //     } else {
  //       throw new Error("Could not fetch publishing info, response " + response.status )
  //     }
  //   } catch (error) {
  //     // TODO: Log to Server
  //     console.error('Error fetching publishing data ', error);  
  //   }
  // }

  // // Extract streamName for MC Connection
  // const getStreamName = (camera) => {
  //   if (!streams || !camera) { return } 
  //   let streamName = streams.filter((stream) => (stream.streamName.split('_')[0] === camera));
  //   if (streamName) { return streamName } 
  //   else { return }
  // }

  // Utils
  const getCameraEnable = (index) => {
    switch (index) {
      case 0:
        return aEnabled
        break;
      case 1:
        return bEnabled
        break;
      case 2:
        return cEnabled
        break;
      case 3:
        return dEnabled
        break;
    }
  }

  const getCameraLabel = (index) => {
    switch (index) {
      case 0:
        return "A"
        break;
      case 1:
        return "B"
        break;
      case 2:
        return "C"
        break;
      case 3:
        return "D"
        break;
    }
  }

  // Render Actions
  const renderCameras = () => {
    let cameraEls = []
    
    for (let index = 0; index < props.enabledCameras; index++) {
      cameraEls.push(
        <Camera
          label={getCameraLabel(index)}
          enabled={getCameraEnable(index)}
          publisherUuid={props.publisherUuid}
          isBroadcastOnly={props.isBroadcastOnly}
          mcDirUrl={props.mcDirUrl}
          mcDirToken={props.mcDirToken}
          mcStreams={props.mcStreams}
          cssClasses={props.cssClasses}
          key={"cam_" + index}
        />
      )
    }
    return cameraEls
  }

  // Render
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={props.cssClasses.tableBody}>

        {/* Desktop */}
        <Hidden mdDown>
          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} className={props.cssClasses.tableCell}>
              { renderCameras() }
            </Grid>
          </Grid>
        </Hidden>

        {/* Mobile */}
        <Hidden mdUp>

          <Grid container className={props.cssClasses.tableRow}>
            <Grid item xs={12} className={props.cssClasses.tableHeader}>

            </Grid>
            <Grid item xs={12} className={props.cssClasses.tableCell}>

            </Grid>
          </Grid>
        </Hidden>

      </Grid>

    </Grid>
  );

}
