import React, { useEffect, useState, useRef } from 'react';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
Hidden,
Typography,
FormControl,
FormControlLabel,
Input,
InputLabel,
Select,
MenuItem,
Button,
Checkbox,
Popover,
} from '@mui/material/';
import { useSnackbar } from 'notistack';
import AudioControls from './audio_controls';
import useCamera from '../../CameraProvider/useCamera';
import { audioChannelTypes } from '../../CameraProvider/Settings/audio_channels';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: theme.spacing(4),
    border: '2px solid ' + theme.palette.secondary.main,
    "&:hover": {
      border: '2px solid ' + theme.palette.primary.main,
    },
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(3),
  },
  section: {
    margin: 'auto',
  },
  sectionTitle: {
    paddingRight: '12px',
    textDecoration: 'underline',
    color: theme.palette.text.secondary,
  },
  title: {
    color: theme.palette.text.secondary,
    borderBottom: "1px solid " + theme.palette.secondary.main
  }
}));

interface Props {

}

export default function Audio(props: Props) {
  // Hooks
  const {
    enabled,
    label,
    audioInputDevices,
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    echoCancellation,
    noiseSuppression,
  } = useCamera();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  // State
  const [showSettings, setShowSettings] = useState<boolean>(false);

  // Refs
  const containerRef = useRef(null);

  // Effects

  // Actions

  // Setting dialog controls
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  }
  const closeSettings = () => {
    setShowSettings(false);
  }

  // Render
  return (
    <Grid 
      container 
      className={classes.container}
        onClick={toggleSettings}
        ref={containerRef}
    >

      <Grid
        item
        xs={9}
        className={classes.section}
      >
        <Typography
          align="left"
          variant="subtitle2"
          className={classes.sectionTitle}
        >
          Audio Input:
        </Typography>
        <Typography
          align="left"
        >
          {
            audioInputDevices?.find(adev => adev.deviceId === audioDeviceId)?.label
          }
          {
            audioDeviceId === "none" &&
            "NO AUDIO"
          }
        </Typography>
      </Grid>

      <Grid item xs={3} className={classes.section}>
        <Typography
          align="right"
          display="block"
          variant="subtitle2"
        >
          {
            audioChannelTypes?.find(ac => ac.id === audioChannels)?.label
          }
        </Typography>
        {
          echoCancellation &&
          "Echo Cancellation ON"
        }
        {
          noiseSuppression &&
          "Noise Suppression ON"
        }
        {/* Show level meter here ? */}
      </Grid>

      <Popover
        onClose={closeSettings}
        open={showSettings}
        anchorEl={containerRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      >
        <AudioControls />
      </Popover>

    </Grid>
  );

}
