import React, { useEffect, useState, useRef, ReactNode } from 'react';
import { Sketch, Marker } from '../sketches_types';
import Pusher, { Channel, PresenceChannel, Members } from 'pusher-js';
import mergeSketchHistory from '../Utils/mergeSketchHistory';
import mergeMarkerHistory from '../Utils/mergeMarkerHistory';
import clearCanvas from '../Utils/clearCanvas';
import dayjs, { Dayjs } from 'dayjs';

interface Props {
  children: ReactNode,
  enabled: boolean,
  disable: () => void,
  enable: () => void,
  cam_index: number,
  viewerUuid: string,
  room: string,
  pusher: Pusher,
  presenceRoom: PresenceChannel | undefined,
  members: Members | undefined,
  viewer_color: string,
  displayEl: React.RefObject<HTMLElement>,
}

interface Context {
  markerHistory: Marker[],
  mergeMarkers: (markers: Marker[], append: boolean, updateAt: Dayjs) => void,
  sketchHistory: Sketch[],
  setSketchHistory: (sketchHistory: Sketch[]) => void,
  mergeSketches: (sketches: Sketch[], append: boolean, updateAt: Dayjs) => void,
  canvasRef: React.RefObject<HTMLCanvasElement>,
  canvasRefRemote: React.RefObject<HTMLCanvasElement>,
  getSketches: () => void,
  allowDrawing: boolean,
  canTrackMouse: boolean,
  sketchType: string,
  setSketchType: (sketchType: string) => void,
  showMarker: boolean,
  setShowMarker: (showMarker: boolean) => void,
  showControls: boolean,
  setShowControls: (showControls: boolean) => void,
  setCanTrackMouse: (canTrackMouse: boolean) => void,
  pusher: Pusher | undefined,
  sbPusher: Channel | undefined,
  presenceRoom: PresenceChannel | undefined,
  members: Members | undefined,
  updateMembers: boolean,
  viewerUuid: string,
  viewer_color: string,
  cam_index: number,
  displayEl: React.RefObject<HTMLElement>,
  clearMySketches: () => void,
  clearAllSketches: () => void,
}

interface Member {
  id: string,
  info: object
}

export const SketchesContext = React.createContext<Context>(null!);

export default function SketchesProvider(props: Props) {

  // State
  const [allowDrawing, setAllowDrawing] = useState<boolean>(false);
  const [canTrackMouse, setCanTrackMouse] = useState<boolean>(false);
  const [sketchType, setSketchType] = useState<string>('');
  const [showMarker, setShowMarker] = useState<boolean>(false);
  const [sbPusher, setSbPusher] = useState<Channel>();
  const [members, setMembers] = useState<Members | undefined>();
  const [updateMembers, setUpdateMembers] = useState<boolean>(false);
  const [sketchHistory, setSketchHistory] = useState<Sketch[]>([]);
  const [sketchLastUpdate, setSketchLastUpdate] = useState<Dayjs>(dayjs(0));
  const [markerHistory, setMarkerHistory] = useState<Marker[]>([]);
  const [markerLastUpdate, setMarkerLastUpdate] = useState<Dayjs>(dayjs(0));
  const [showControls, setShowControls] = useState<boolean>(false);


  // Refs
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasRefRemote = useRef<HTMLCanvasElement>(null);
  const sketchHistoryRef = useRef<Sketch[] | null>(null);
  const markerHistoryRef = useRef<Marker[] | null>(null);

  // Get drawing history on load
  useEffect(() => {
    getSketches();
  }, []);

  useEffect(() => {
  sketchHistoryRef.current = sketchHistory
  }, [sketchHistory]);

  useEffect(() => {
  markerHistoryRef.current = markerHistory
  }, [markerHistory])
 
  useEffect(() => {
    if (props.pusher) {

      let sbChannel:Channel = props.pusher.channel('private-sb_' + props.cam_index + '_' + props.room);

      if (sbChannel) {
        setSbPusher(sbChannel);
      } else {
        // Subscribe to SketachBoard Room
        let sbChannel:Channel = props.pusher.subscribe('private-sb_' + props.cam_index + '_' + props.room);

        // Store Channel Reference once connected
        sbChannel.bind('pusher:subscription_succeeded', (success:string) => {
          setSbPusher(sbChannel);
        });

        // Update History when changed
        sbChannel.bind('client-sb_get_sketches', (message:string) => {
          getSketches();
        });
      }
    }
    return () => {
      if (props.pusher) {
        props.pusher.unsubscribe('private-sb_' + props.cam_index + '_' + props.room)
      }
    };
  }, [props.pusher]);

  // Don't allow drawing when we are not setup
  useEffect(() => {
    if (
      sbPusher &&
      canvasRef &&
      canvasRefRemote
    ) {
      setAllowDrawing(true);
    } else {
      setAllowDrawing(false);
    }
  }, [sbPusher, canvasRef, canvasRefRemote]);

  useEffect(() => {
    if (canTrackMouse && canvasRef?.current) {
      canvasRef.current.style.border = '1px solid ' + props.viewer_color
    } else if (!canTrackMouse && canvasRef?.current) {
      canvasRef.current.style.border = 'none'
    }
  }, [canTrackMouse]);
 
  // Actions
  const getSketches = async () => {
    // Get Sketches
    try {
      let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_history/' + props.viewerUuid, { credentials: "include" });
      if (res.ok) {
        let response = await res.json();
        if (response.sketches) {
          mergeSketchHistory(
            sketchHistory,
            setSketchHistory,
            response.sketches.filter((sketch:Sketch) => sketch.cam_index === props.cam_index),
            false
          );

          if (response.markers) {
            setMarkerHistory(response.markers);
          }
        }
      }
    } catch (err) {
      console.error('Network error getting sketch history ', err);
    }
  }
 
  const clearMySketches = async () => {

    // Clear locally right away
    let currentHistory:Array<Sketch> = [...sketchHistory]
    currentHistory = currentHistory.filter((sketch:Sketch) => sketch.viewerUuid !== props.viewerUuid);
    setSketchHistory(currentHistory);

    // Remove history in DB
    try {
      let fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          clear: props.viewerUuid // 'All' or viewer UUID
        }),
      }
      let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_clear/' + props.viewerUuid, fetchOpts)
      if (res.ok) {
        
        // Send call to update history
        if (sbPusher) {
          sbPusher.trigger('client-sb_get_sketches', "true");
        }
      }
    } catch (e) {
      console.error('Network error sending clear ' + props.viewerUuid, e);
    }
  }
 
  const clearAllSketches = async () => {
    let updateAt = dayjs();
    
    // Reset local history
    setSketchHistory([]);
    setSketchLastUpdate(updateAt);

    // Reset stored history
    try {
      let fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          clear: 'All' // 'All' or viewer UUID
        }),
      }
      let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_clear/' + props.viewerUuid, fetchOpts)
      if (res.ok) {
        let response = await res.json();
        
        // Update markers
        if (response?.markers) {
          setMarkerHistory(response.markers)
        }

        // Broadcast the clear command to other viewers
        if (sbPusher) {
          sbPusher.trigger('client-sb_get_sketches', "true");
        }
      }
    } catch (e) {
      console.error('Network error sending clear all', e);
    }
  }

  const mergeSketches = (sketches: Sketch[], append: boolean, updateAt: Dayjs): void => {
    // Only update if this is the latest history request from the server
    if (updateAt.diff(sketchLastUpdate) > 0) {
      setSketchLastUpdate(updateAt);
      mergeSketchHistory(
        sketchHistoryRef.current ? sketchHistoryRef.current : [],
        setSketchHistory,
        sketches,
        append
      );
    }
  }

  const mergeMarkers = (markers: Marker[], append: boolean, updateAt: Dayjs): void => {
    // Only update if this is the latest history request from the server
    if (updateAt.diff(markerLastUpdate) > 0) {
      setMarkerLastUpdate(updateAt);
      mergeMarkerHistory(
        markerHistoryRef.current ? markerHistoryRef.current : [],
        setMarkerHistory,
        markers,
        append
      );
    }
  }

  return (
    <SketchesContext.Provider
      value={{
        markerHistory: markerHistory,
        mergeMarkers: mergeMarkers,
        sketchHistory: sketchHistory,
        setSketchHistory: setSketchHistory,
        mergeSketches: mergeSketches,
        canvasRef: canvasRef,
        canvasRefRemote: canvasRefRemote,
        getSketches: getSketches,
        allowDrawing: allowDrawing,
        canTrackMouse: canTrackMouse,
        setCanTrackMouse: setCanTrackMouse,
        sketchType: sketchType,
        setSketchType: setSketchType,
        showMarker: showMarker,
        setShowMarker: setShowMarker,
        showControls: showControls,
        setShowControls: setShowControls,
        pusher: props.pusher,
        sbPusher: sbPusher,
        presenceRoom: props.presenceRoom,
        members: props.members,
        updateMembers: updateMembers,
        viewerUuid: props.viewerUuid,
        viewer_color: props.viewer_color,
        displayEl: props.displayEl,
        cam_index: props.cam_index,
        clearMySketches: clearMySketches,
        clearAllSketches: clearAllSketches,
      }}
    >
      {props.children}
    </SketchesContext.Provider>
  );
}
