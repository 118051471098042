import React, { useEffect, useState } from 'react';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clearCanvas from '../Utils/clearCanvas';
import drawSketchHistory from '../Utils/drawSketchHistory';
import resizeElement from '../Utils/resizeElement';
import setupCanvas from '../Utils/setupCanvas';
import drawRoundRect from '../Utils/drawRoundRect';
import { Sketch, mEvent } from '../sketches_types';
import { v4 as uuidv4 } from 'uuid';

import useSketches from '../Context/useSketches';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },
  canvas: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    touchAction: 'none',
    pointerEvents: 'none'
  }
}));

// Track mouse events to send
// This has to be outside of our function because new items are pushed from
// within a mouse event handler and that is outside our functions scope
var mouseEvents:Array<mEvent> = [];
var created: Date = new Date();
var sketchUuid: string = uuidv4();

// ** Real time sending is disabled for now **
// var lastSent = 0;
// var sendTrigger:number | undefined;
var stopTrigger:ReturnType<typeof setTimeout> | undefined;

export default function SketchesCanvasLocal(props:any) {
  // Hooks
  const classes = useStyles();
  const {
    sketchHistory,
    canvasRef,
    viewer_color,
    sbPusher,
    canTrackMouse,
    viewerUuid,
    displayEl,
    sketchType,
    cam_index,
    mergeSketches,
    setSketchType,
    setCanTrackMouse
  } = useSketches();

  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null | undefined>();
  const [trackMouse, setTrackMouse] = useState<boolean>(false);
  const sendInterval: number = 200;
  const listenerController: AbortController = new AbortController();
  const listenerOpts: AddEventListenerOptions & EventListenerOptions = { 
    passive: true,
    signal: listenerController.signal
  }
  const [resetListener, setResetListener] = useState<boolean>(false);
  
  // Draw the history
  useEffect(() => {
    if (canvasContext && sketchHistory) {
      drawSketchHistory(canvasContext, sketchHistory, viewerUuid, 'local', []);
    }
  }, [canvasContext, sketchHistory]);

  // Get the canvas context once the canvas is available
  useEffect(() => {
    if (!canvasContext && canvasRef?.current?.getContext) {
      setCanvasContext(canvasRef.current.getContext('2d'));
    }
  }, [canvasRef]);

  // Update canvas pixel count
  useEffect(() => {
    if (canvasContext?.canvas) {
      // Context setup
      setupCanvas(canvasContext, viewer_color)

      // Size the canvas
      resizeElement(canvasContext.canvas);

      // Store our default settings
      canvasContext.save();
    }
  }, [canvasContext]);

  // Only track mouse events when drawing is selected
  useEffect(() => {
    // TODO: displayEl changes when an RTC connection is made
    // This causes the event listeners to dissappear ?? and
    // Will crash the app if drawing happens across that change

    // Add mouse click listeners
    if (canTrackMouse && displayEl?.current) {
      // Change cursor look
      document.body.style.cursor = "cell";
      
      // Stop the page from moving on touch events
      displayEl.current.style.touchAction = 'none'
      
      displayEl.current.addEventListener('pointerdown', startTracking, listenerOpts);
    } else {
      // Change cursor look
      document.body.style.cursor = "auto";
      
      // Allow page scrolling
      if (displayEl?.current) {
        displayEl.current.style.touchAction = 'auto'
      }
    }

    return () => {
      if (displayEl?.current) {

        // Change cursor look
        document.body.style.cursor = "auto";
        listenerController.abort();
      }
    };
  }, [canTrackMouse, displayEl?.current, resetListener]);

  // Toggle cursor tracking
  useEffect(() => {
    if (displayEl?.current) {
      if (trackMouse) {
        displayEl.current.addEventListener('pointermove', trackCursor, listenerOpts)

        displayEl.current.addEventListener('pointerout', pauseTracking, listenerOpts)

        displayEl.current.addEventListener('pointerenter', resumeTracking, listenerOpts)

        displayEl.current.addEventListener('pointerup', (event) => {
          stopTracking(event);
        }, listenerOpts)
        // displayEl.current.addEventListener('pointercancel', (event) => {
        //   stopTracking(event);
        // }, listenerOpts)
        displayEl.current.addEventListener('lostpointercapture', (event) => {
        }, listenerOpts)

      } else {
        listenerController.abort();
        setResetListener(!resetListener);

        // Allow the page from moving on touch events
        displayEl.current.style.touchAction = 'auto'
      }
    }
    return () => {
      if (displayEl?.current) {
        listenerController.abort();
        setResetListener(!resetListener);
      }
    };
  }, [trackMouse]);

  // ** Actions **
  function startTracking(click:PointerEvent) {
    // Do nothing if we are already tracking
    if (trackMouse) return;
    
    // This happens occassionally.  Maybe between video connection states???
    // TODO: Track down the source of this
    if (!displayEl?.current) return;
    if (!canvasContext?.canvas) return;

    // Ignore click events that overlap with the control bar
    if (click.offsetY < 36) return;

    // Verify the canvas size is correct
    let parentNode: HTMLElement = canvasContext?.canvas?.parentNode?.parentNode as HTMLElement;
    if (
      canvasContext?.canvas &&
      (canvasContext?.canvas?.width !== parentNode?.clientWidth ||
      canvasContext?.canvas?.height !== parentNode?.clientHeight)
    ) {
      resizeElement(canvasContext.canvas);
    }

    // // Stop the page from moving on touch events
    if (displayEl?.current) {
      displayEl.current.style.touchAction = 'none'
    }

    // Context setup
    setupCanvas(canvasContext, viewer_color);

    // Create new path object
    canvasContext.beginPath();
    canvasContext.moveTo(click.offsetX, click.offsetY);

    // Store initial mouse event
    created = new Date();
    sketchUuid = uuidv4();
    let mEvent:mEvent = {
      x: click.offsetX,
      y: click.offsetY,
      count: 0
    }
    mouseEvents = [mEvent];


    // Track mouse movements
    setTrackMouse(true);

    // ** Real Time sending is disabled for now **
    // Begin sending data if we aren't already
    // if (!sendTrigger) {
    //   sendTrigger = Number(setInterval(sendNewPath, sendInterval));
    // }
  }

  // Handle each cursor event
  async function trackCursor(event:PointerEvent) {

    // Don't allow page scrolling 
    if (displayEl?.current) {
      displayEl.current.style.touchAction = 'none';
    }

    // Stop tracking if no mouse button is clicked
    // Typically this will be handled by the resumeTracking listener before reaching here
    if (event.buttons === 0) { 
      stopTracking(event);

      // Allow the page to move on touch events
      if (displayEl?.current) {
        displayEl.current.style.touchAction = 'auto';
      }
      return 
    };

    // Do nothing if we don't have a context or a path to add to
    if (!canvasContext) { return };

    // Don't draw movements under the control bar
    if (event.offsetY < 36) { return }

    switch (sketchType) {
      default:
      case 'sketch':
        // Draw mouse movement
        canvasContext.lineTo(
          event.offsetX,
          event.offsetY
        );
        canvasContext.stroke();

        // Store mouse event
        let mEvent = {
          x: event.offsetX,
          y: event.offsetY,
          count: mouseEvents.length
        }
        mouseEvents.push(mEvent);
        break;
      case 'line':
        if (mouseEvents.length > 0) {
          clearCanvas(canvasContext);
          drawSketchHistory(canvasContext, sketchHistory, viewerUuid, 'local', []);
          canvasContext.beginPath();
          canvasContext.moveTo(mouseEvents[0].x, mouseEvents[0].y);
          canvasContext.lineTo(event.offsetX, event.offsetY);
          canvasContext.stroke();
        }
        break;
      case 'box':
        if (mouseEvents.length > 0) {
          clearCanvas(canvasContext);
          drawSketchHistory(canvasContext, sketchHistory, viewerUuid, 'local', []);
          canvasContext.beginPath();
          drawRoundRect(
            canvasContext,
            mouseEvents[0],
            {
              x: event.offsetX,
              y: event.offsetY
            }
          );
        }
        break;
    }
  }

  // Stop sending data temporarily
  async function pauseTracking(click:PointerEvent) {
    if (trackMouse) {
      // Change cursor look
      document.body.style.cursor = "auto";
      
      // ** Real time sending is disabled for now **
      // clearInterval(sendTrigger);
      // sendTrigger = undefined;
    }
  }

  // Resume sending data
  async function resumeTracking(click:PointerEvent) {
    if (click.buttons > 0 && trackMouse) {
      // Change cursor look
      document.body.style.cursor = "cell";

      if (displayEl?.current) {
        displayEl.current.style.touchAction = 'none';
      }
      // ** Real time sending is disabled for now **
      // sendTrigger = Number(setInterval(sendNewPath, sendInterval));
    } else {
      stopTracking(click);
    }
  }

  // Stop Tracking
  async function stopTracking(click:PointerEvent) {
    let updateAt = dayjs();

    // Store final event
    switch (sketchType) {
      default:
      case 'sketch':

        break;
      case 'line':
      case 'box':
        // We don't store positions during drawing so we need to store our final spot
        mouseEvents.push({
          x: click.offsetX,
          y: click.offsetY,
          count: mouseEvents.length
        })
        break;
    }

    // Stop Tracking
    setTrackMouse(false);

    // Remove event listeners
    if (displayEl?.current) {
      // displayEl.current.removeEventListener('pointermove', trackCursor, listenerOpts)
      // displayEl.current.removeEventListener('pointerup', stopTracking, listenerOpts);

      listenerController.abort();
      setResetListener(!resetListener);
      
      // Allow page scrolling
      displayEl.current.style.touchAction = 'auto'
    }

    // Stop sending event updates
    // ** Real time sending is disabled for now **
    // clearInterval(sendTrigger);
    // sendTrigger = undefined;

    // Stop editing after X seconds with no movements
    if (stopTrigger) {
      // Clear the existing timer to reset the amount of time before editing stops
      clearInterval(stopTrigger);
    }
    // Set timer to stop editing
    stopTrigger = setTimeout((): void => {
      setSketchType('')
      setCanTrackMouse(false)
    }, 6000);

    // Store local History
    let currentSketch: Sketch = {
      uuid: sketchUuid,
      viewerUuid: viewerUuid,
      sketchType: sketchType,
      created: created,
      updated: new Date(),
      color: viewer_color,
      cam_index: cam_index,
      canvasWidth: canvasContext!.canvas.width,
      canvasHeight: canvasContext!.canvas.height,
      visible: true,
      undone: false,
      mEvents: mouseEvents
    }

    // Send final event to remote
    sendNewPath();

    // Send current drawing to server
    let stringed = JSON.stringify({
      action: 'add',
      sketches: [currentSketch]
    });

    // Send to server
    try {
      let fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: stringed,
      }
      
      let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_history/' + viewerUuid, fetchOpts)
      if (res.ok) {
        let response = await res.json();

        // Update history
        if (response?.sketches) {
          mergeSketches(
            response.sketches.filter((sketch:Sketch) => sketch.cam_index === cam_index),
            false,
            updateAt
          );

        }
      }
    } catch (e) {
      console.error('Network error sending mouse event history ', e);
    }
  }

  // ** Real time sending is disabled for now **
  // Send drawing to remote peers
  async function sendNewPath() {
    // ** This is from real time sending in peices which is disabled
    // // Freeze the data length as the array is constantly changing
    // let chunkEnd:number = Number(mouseEvents.length)

    let newData: Sketch = {
      uuid: sketchUuid,
      viewerUuid: viewerUuid,
      sketchType: sketchType,
      created: created,
      updated: new Date(),
      color: viewer_color,
      cam_index: cam_index,
      canvasWidth: canvasContext!.canvas.width,
      canvasHeight: canvasContext!.canvas.height,
      visible: true,
      undone: false,
      mEvents: mouseEvents
    }
    // ** This is from real time sending in peices which is disabled
    // mEvents: mouseEvents.slice(lastSent > 0 ? lastSent - 2 : 0, chunkEnd)

    // Copy and prepare event data
    let stringed = JSON.stringify(newData);


    // ** This is from real time sending in peices which is disabled
    // update counter
    // lastSent = chunkEnd;
    
    // Send data
    if (sbPusher) {
      sbPusher.trigger('client-sb_drawing', stringed);
    }
  }

  // Render
  return (
    <canvas
      id={"sb_" + cam_index}
      ref={canvasRef}
      className={classes.canvas}
      width="1920"
      height="1080"
      style={{
        zIndex: 17
      }}
    >
    </canvas>
  );

}
