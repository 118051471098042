import React, { Component } from 'react';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { withSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie';
import Hidden from '@mui/material/Hidden';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/Chat';
import ChatInput from './chat_input';
import ChatBubble from './chat_bubble';
import VCActiveSpeaker from '../Twilio/VC_ActiveSpeaker';
import Tooltip from '@mui/material/Tooltip';

const axios = require('axios').default;

const useStyles = (theme) => ({

  communications: {
    height:'100%',
    width: '300px',
    transition: 'width 600ms',
  },
  communicationsClosed: {
    height:'100%',
    width: '0px',
    transition: 'width 600ms',
  },
  chatNoVideo: {
    // height: (props) => (props.isViewer ? 'calc(100vh - 286px)' : 'calc(100vh - 346px)'),
    height: (props) => (props.isViewer ? 'calc(100% - 286px)' : 'calc(100% - 346px)'),
    // height: 'calc(100% - 346px)',
    padding: theme.spacing(1, 0, 1, 0),
    borderRadius: 6,
    // [theme.breakpoints.down('md')]: {
      // height: theme.palette.mode === 'dark' ? 'calc(100vh - 370px)' : 'calc(100vh - 450px)',
    // },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 58px)'
    },
    transition: 'height 600ms',
  },
  chatWVideo: {
    // height: (props) => (props.isViewer ? 'calc(100vh - 544px)' : 'calc(100vh - 606px)'),
    height: (props) => (props.isViewer ? 'calc(100% - 544px)' : 'calc(100% - 606px)'),
    // height: 'calc(100% - 604px)',
    padding: theme.spacing(1, 0, 1, 0),
    borderRadius: 6,
    // [theme.breakpoints.down('md')]: {
      // height: theme.palette.mode === 'dark' ? 'calc(100vh - 628px)' : 'calc(100vh - 690px)',
    // },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 58px)'
    },
    transition: 'height 600ms',
  },
  chatNoVideoNoVC: {
    // height: (props) => (props.isViewer ? 'calc(100vh - 80px)' : 'calc(100vh - 134px)'),
    height: (props) => (props.isViewer ? 'calc(100% - 84px)' : 'calc(100% - 140px)'),
    // height: 'calc(100% - 140px)',
    padding: theme.spacing(1, 0, 1, 0),
    borderRadius: 6,
    // [theme.breakpoints.down('md')]: {
      // height: theme.palette.mode === 'dark' ? 'calc(100vh - 164px)' : 'calc(100vh - 226px)',
    // },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 58px)'
    },
    transition: 'height 600ms',
  },
  chatWVideoNoVC: {
    // height: (props) => (props.isViewer ? 'calc(100vh - 331px)' : 'calc(100vh - 389px)'),
    height: (props) => (props.isViewer ? 'calc(100% - 331px)' : 'calc(100% - 389px)'),
    // height: 'calc(100% - 390px)',
    padding: theme.spacing(1, 0, 1, 0),
    borderRadius: 6,
    // [theme.breakpoints.down('md')]: {
      // height: theme.palette.mode === 'dark' ? 'calc(100vh - 423px)' : 'calc(100vh - 485px)',
    // },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 58px)'
    },
    transition: 'height 600ms',
  },
  topPadding: {
    flexShrink: 1,
    flexGrow: 1,
    minHeight: theme.spacing(1),
  },
  scrollBox: {
    height: 'calc(100% - 54px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 240px)',
      minHeight: '150px'
    },
    transition: 'height 600ms'
  },
  messageList: {
    padding: theme.spacing(2, 2, 8, 2),
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  chatInput: {
    paddingRight:'4px',
    paddingLeft:'4px',
    [theme.breakpoints.down('md')]: {
      paddingLeft:'62px'
    }
  },
  toggleChatButtonClosed: {
    position:'fixed',
    top:'49px',
    right: '10px',
    transition: 'right 800ms',
    zIndex:1201,
    backgroundColor: theme.palette.background.light,
    filter: 'drop-shadow(2px 4px 6px black)',
    '&:hover': {
      backgroundColor: theme.palette.background.subtle,
    },
    color: theme.palette.primary.light,
    borderRadius:'4px',
    padding:'3px',
  },
  toggleChatButtonOpen: {
    position:'fixed',
    top:'47px',
    right: '267px',
    transition: 'right 800ms',
    zIndex:1201,
    backgroundColor: theme.palette.background.light,
    filter: 'drop-shadow(4px 0px 6px black)',
    '&:hover': {
      backgroundColor: theme.palette.background.subtle,
    },
    color: theme.palette.primary.light,
    borderRadius:'4px',
    padding:'3px',
  },
  chatBadge: {
    filter: 'drop-shadow(4px 4px 6px ' + theme.palette.background.dark + ')'
  },

});

// Chat Component
class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: '',
      role: props.role,
      status: 'Welcome!!',
      channelId: props.room,
      channel: props.channel,
      members:  [{ // (members[0] is always self)
        uuid: props.uuid,
        room: props.room,
        nickName: props.nickName,
      }],
      newMessage: [],
      chatOpen: false,
      withActiveSpeaker: true,
      unreadChats: 0,
      notify_is_allowed: false
    }

    this.updateMessageState = this.updateMessageState.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  setWithActiveSpeaker(withSpeaker) {
    this.setState({
      withActiveSpeaker: withSpeaker
    });
  }

  listenForChats() {

    // On Success
    this.props.channel.bind('pusher:subscription_succeeded', success => {
      // console.log('Chat subscribed, update history ');
      // Get missed messages
      this.getChatHistory();
    });

    // Receive chats
    // if (this.state.channel) {
      this.props.channel.bind('chat', (message) => {

        if (typeof message === 'object') {
          let toPush = this.state.newMessage;
          toPush.push({
              nickName: message.nickName,
              message: message.message,
              uuid: message.uuid,
              timestamp: message.timestamp,
          });
          this.setState({
            newMessage: toPush
          });

          // Scroll to the bottom of the chat
          let listElement = document.getElementById('scrollBox');
          if (listElement) {
            listElement.scrollTop = listElement.scrollHeight + 100;
          } else {
            // console.log('DoNT ScROLL ', listElement);
          }

          // Update unread count if not my message
          if (message.uuid !== this.props.uuid) {
            this.setState({ unreadChats: this.state.unreadChats + 1 })
          }

          // Don't show notification if this is your message
          if (message.uuid !== this.props.uuid && window.Notification) {
            // console.log('Trying notification ', message.uuid, this.props.uuid);
            try
            {
              new Notification(
                "New message from " + message.nickName,
                {
                  body: message.message,
                  icon: "https://setstream.io/wp-content/uploads/2020/07/setstream_brand_Icon_512_01.png",
                  badge: "https://setstream.io/wp-content/uploads/2020/07/setstream_brand_logo_color_512_01.png"
                }
              );
            }
            catch (e) {
              console.log('Could not set notification ', e);
            }
          }
        } else {
          console.log('CHAT Invalid message type ', typeof message, message, this.props.uuid);
        }
      });
    // }

      // Update chat list when history is deleted
      this.props.channel.bind('chat_history_deleted', message => {
        console.log('Deleting Chats ', message);
        this.getChatHistory();
      });
  }

  // Update state
  updateMessageState(event) {
    this.setState({
      inputText: event.target.value,
    });
  }

  // async updateNickName(nickName) {
  //   try {
  //     let fetchUrl = process.env.REACT_APP_API_URL + '/api/chat/nickName/' + this.props.uuid
  //     let fetchData = {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type':'application/json'
  //       },
  //       credentials: "include",
  //       body: JSON.stringify({
  //         nickName: nickName,
  //         uuid: this.props.uuid
  //       })
  //     }
  //     let res = await fetch(fetchUrl, fetchData);
  //     if (res.ok) {
  //       let response = await res.json();
  //     } else {
  //       console.error('Could not update nickName ', res.status);
  //     }  
  //   } catch (error) {
  //     console.error('Network error updating nickName ', error);
  //   }
  // }

  sendChat(target, source, message) {
    axios.post(process.env.REACT_APP_API_URL + '/api/pusher/chat/' + this.state.channelId +'/'+ this.props.uuid, message, { withCredentials: true })
    .then(result => {
      switch (result.data.type) {
        case 'success':
          // console.log('Chat sent ', result.data.message);
          break;
        case 'logged_out':
          this.props.enqueueSnackbar('Looks like you are logged out.  Please log in again.', { variant: 'info' });
          console.log('Logged out ', result.data.message);
          window.location.reload(false);
          break;
        case 'unauthorized':
          Cookies.remove(this.state.subscriber_uuid, { domain: process.env.REACT_APP_DOMAIN, path: '/' });
          this.props.enqueueSnackbar('Access has been removed.  If you think this is an error please contact your project manager.', { variant: 'warning' });
          console.log('Access denied');
          window.location.reload(false);
          break;
        case 'error':
          console.error('Could not store chat in history');
          break;
        default:
        // Nothing happens
      }
    })
    .catch(err => {
      // this.props.enqueueSnackbar('There was an error sending your message', { variant: 'warning' });
      console.error('Error sending chat- ', err);
    });
  }

  sendMessage(event) {
    event.preventDefault();
    if ( event.target && event.target[0].value && event.target[0].value.length > 0 ) {
      let nickName = localStorage.getItem('ss_nickname');
      if (!nickName) {
        nickName = window.prompt("Please identify yourself for others");
        if (nickName && nickName !== "") {
          localStorage.setItem('ss_nickname', nickName);
          this.props.updateNickName(nickName)
        }
      }
      let now = new Date();
      let constructed = {
        uuid: this.props.uuid,
        nickName: nickName,
        message: event.target[0].value,
        timestamp: now,
      }
      this.sendChat('All', this.props.uuid, constructed);
      this.setState({
        inputText: ''
      });
    }

    // Scroll to the bottom of the chat
    let listElement = document.getElementById('scrollBox');
    if (listElement) {
      listElement.scrollTop = listElement.scrollHeight + 100;
    }
  }

  openChat() {
    this.setState({chatOpen: !this.state.chatOpen}, () => {
      // Scroll to the bottom of the chat
      let listElement = document.getElementById('scrollBox');
      if (listElement) {
        listElement.scrollTop = listElement.scrollHeight + 100;
      }

    });
  }

  // Fix for Safari using callbacks instead of promises for Notification permissions
  checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch(e) {
        return false;
      }
      return true;
    }

  // Download Chat history
  getChatHistory() {
    // console.log('Getting chat history ', this.state.channelId);
    axios.get(process.env.REACT_APP_API_URL + '/api/chat_history/' + this.props.uuid, { withCredentials: true })
    .then(chats => {
      // console.log('Received chat history ');

      this.setState({
        newMessage: chats.data.chatMessages,
        status: chats.data.status
      });
      let listElement = document.getElementById('scrollBox');
      if (listElement) {
        listElement.scrollTop = listElement.scrollHeight + 100;
      }
    })
    .catch(err => {
      console.error('Chat API Error - ', err);
    });
  }

  // Close chat drawer
  closeChatDrawer() {
    this.props.setIsChatOpen();
    this.setState({
      unreadChats: 0
    });
  }

  // Lifecycle
  componentDidMount() {
    this.getChatHistory();
    this.listenForChats();

    // Put controls in appBar
    this.props.setChatControls(
      <Tooltip title="Text Chat">
        <Button
          onClick={() => this.openChat()}
          color="primary"
          variant = "contained"
          style={{
            // backgroundColor:'rgba(58,58,58,.9)',
            'padding':'3px',
            minWidth:'32px',
            height:'32px',
            marginRight:'4px'
          }}
        >
          <Badge
            badgeContent={this.state.unreadChats > 0 ? this.state.unreadChats : null} color="primary"
          >
            <ChatIcon 
              // color="primary" 
            />
          </Badge>
        </Button>
      </Tooltip>
    )

    // Get permission for notifications
    if (window.Notification && Notification.permission && typeof Notification.requestPermission === 'function') {
      switch (Notification.permission) {
        case 'granted':
          this.setState({
            notify_is_allowed: true
          });
          // console.log('Notifications permission granted');
          break;
        case 'denied':
          console.log('Notifications permission denied');
          break;
        case 'default':
          if(typeof Notification.requestPermission === 'function') {
            if (this.checkNotificationPromise()) {
              Notification.requestPermission()
              .then(permission => {
                if (permission === "granted") {
                  this.setState({
                    notify_is_allowed: true
                  });
                }
              })
              .catch(err => {
                console.error('Error - Could not get permission for notifications', err);
              });
            } else {
              Notification.requestPermission(permission => {
                if (permission === "granted") {
                  this.setState({
                    notify_is_allowed: true
                  });
                }
              });
            }
          }
      }
    }

    // To Scroll, or not to scroll ???
    let listElement = document.getElementById('scrollBox');
    if (listElement) {
      listElement.scrollTop = listElement.scrollHeight + 100;
    }
  }

  pieces(classes) {

    return (
      <Grid container className={
        // this.props.isVCOpen ?
          // this.state.withActiveSpeaker ?
          //   classes.chatWVideo :
          //   classes.chatNoVideo
          // :
          this.state.withActiveSpeaker ?
            classes.chatWVideoNoVC :
            classes.chatNoVideoNoVC
      }>

        <Grid item xs={12} className={classes.scrollBox} id="scrollBox">
          <Box className={classes.messageList}>
          {
            this.state.newMessage.map((message, i) =>
              <ChatBubble
                key={'CB'+i}
                message={message}
                isLocal={message.uuid === this.props.uuid}
              />
            )
          }
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.chatInput}>
          <ChatInput
            onSend={this.sendMessage}
          />
        </Grid>

      </Grid>
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        direction="column"
        id='communications'
        className={this.props.isChatOpen ? classes.communications : classes.communicationsClosed}
      >
        <Grid item style={{height:'100%', width:'100%', borderleft: '1px solid rgba(31, 105, 137, 1)'}}>
          <Hidden mdDown>

            {
              this.props.isAllowed &&
              <Tooltip title="Text Chat">
                <IconButton
                  onClick={() => this.closeChatDrawer()}
                  color="secondary"
                  className={this.props.isChatOpen ? classes.toggleChatButtonOpen : classes.toggleChatButtonClosed}
                  disabled={!this.props.isAllowed && !this.props.isChatOpen}
                  size="large">
                  {
                    !this.props.isChatOpen &&
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={this.state.unreadChats > 0 ? this.state.unreadChats : null} color="primary"
                      classes={{
                        badge: classes.chatBadge
                      }}
                    >
                      <ChatIcon color="primary" style={{transform:'rotateY(180deg)'}} />
                    </Badge>
                  }
                  {
                    this.props.isChatOpen &&
                    <DoubleArrowIcon color="primary" />
                  }
                </IconButton>
              </Tooltip>
            }

            <Drawer
              open={this.props.isChatOpen}
              id="chatDrawer"
              anchor='right'
              variant='persistent'
              transitionDuration={600}
              style={{
                height:'100%'
              }}
              PaperProps={{
                style: {
                  height:'100%',
                  position:'relative',
                  overflow:'hidden',
                  border:'none',
                  borderLeft: '1px solid rgba(31, 105, 137, 1)'
                }
              }}
            >
              {this.props.children}
              {this.pieces(classes)}
              <VCActiveSpeaker setWithActiveSpeaker={(v) => this.setWithActiveSpeaker(v)} />
            </Drawer>
          </Hidden>

          <Hidden mdUp>
            {
              // !this.state.chatOpen &&
              // this.props.isAllowed &&
              // <Tooltip title="Text Chat">
              //   <IconButton
              //     onClick={() => this.openChat()}
              //     color="secondary"
              //     style={{
              //       position:'fixed',
              //       bottom:'12px',
              //       left:'12px',
              //       zIndex:1300,
              //       backgroundColor:'rgba(58,58,58,.9)'
              //     }}
              //   >
              //     <Badge
              //       badgeContent={this.state.unreadChats > 0 ? this.state.unreadChats : null} color="primary">
              //       <ChatIcon color="primary" />
              //     </Badge>
              //   </IconButton>
              // </Tooltip>
            }

            <Drawer
              open={this.state.chatOpen}
              onClose={() => this.setState({chatOpen: !this.state.chatOpen, unreadChats: 0})}
              variant="persistent"
              PaperProps = {{style:{
                backgroundColor:'rgba(0,0,0,0.75)',
                width:'100%'
              }}}
            >
              <Tooltip title="Text Chat">
                <IconButton
                  onClick={() => this.setState({chatOpen: !this.state.chatOpen, unreadChats: 0})}
                  color="secondary"
                  style={{
                    position:'fixed',
                    top:'12px',
                    right:'12px',
                    zIndex:1300,
                    backgroundColor:'rgba(58,58,58,.9)'
                  }}
                  size="large">
                  <DoubleArrowIcon 
                    color="primary" 
                    style={{transform:'rotate(180deg)'}}
                  />
                </IconButton>
              </Tooltip>

              {this.props.children}
              {
                this.props.isAllowed &&
                this.pieces(classes)
              }
            </Drawer>
          </Hidden>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(withSnackbar(Chat))
