import useVideoContext from '../useVideoContext/useVideoContext';
import axios from 'axios';

export default function useSubscriptionRules() {

  const { 
    room,
    roomName,
    userUuid
  } = useVideoContext();

  const updateSubRules = (type, data) => {

    let path = `${process.env.REACT_APP_API_URL}/api/vc/${roomName}/${userUuid}/update_subscriptions`
    let dataObject = {
      user_sid: room.localParticipant.sid,
      room_sid: room.sid,
      type: type,
      ...data
    }

    return (
      axios.post(path, dataObject, { withCredentials: true })
    )
  }

  return updateSubRules
}
