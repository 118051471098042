import React from 'react';
import {
LinearProgress,
Grid,
Typography,
} from '@mui/material/';

export default function RoomConnectingStep(props) {

  return (
    <Grid container justifyContent="center" style={{padding: '36px', textAlign:'center'}}>
      <Grid item xs={12}>
        <Typography>
          Please stand by while we connect you ......
        </Typography>
      </Grid>

      <Grid item xs={12} style={{padding: '36px'}}>
        <LinearProgress variant="indeterminate" color="primary" />
      </Grid>
    </Grid>
  );

}
