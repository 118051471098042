import React, { useState, useEffect, useRef } from 'react';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
} from '@mui/material/';
import getCurrentSize from '../Utils/getCurrentSize';
import MarkerIcon from './marker_icon';
import { Marker } from '../sketches_types';
import { v4 as uuidv4 } from 'uuid';
import useSketches from '../Context/useSketches';
import dayjs from 'dayjs';
import { isTouchCapable } from '../../../utils/isTouchCapable';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    pointerEvents: 'none',
  }
}));

// Store the last postion as a sketch object outside of our function for async sending
var marker:Marker | undefined;

// This houses our timer for rate limiting sending events
var sendTrigger:number | undefined;

export default function SketchesMarker(props:any) {
  // Hooks
  const classes = useStyles();

  const {
    showMarker,
    setShowMarker,
    showControls,
    sketchType,
    canTrackMouse,
    markerHistory,
    sbPusher,
    viewerUuid,
    cam_index,
    mergeMarkers,
    displayEl,
    viewer_color
  } = useSketches();

  const listenerController: AbortController = new AbortController();
  const listenerOpts: AddEventListenerOptions & EventListenerOptions = { 
    passive: true,
    signal: listenerController.signal
  }
  const sendInterval: number = 150;

  // State
  const [resetListener, setResetListener] = useState(false);

  // Refs
  const containerRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<HTMLElement>(null);

  // Effects
  useEffect(() => {
    if (
      showMarker &&
      displayEl?.current &&
      !canTrackMouse &&
      (
        isTouchCapable && showControls ||
        !isTouchCapable
      )
    ) {
      // Stop the page from moving on touch events
      displayEl.current.style.touchAction = 'none';

      displayEl.current.addEventListener('pointerdown', startTracking, listenerOpts);
    } else {
      
      // Allow page scrolling again
      if (sketchType === '' && displayEl?.current) {
        displayEl.current.style.touchAction = 'auto'
      }
    }

    return () => {
      if (displayEl?.current) {
        listenerController.abort();
      }
    };
  }, [displayEl, showMarker, resetListener, canTrackMouse, showControls]);

  // Show markers stored in history
  useEffect(() => {
      let markerToShow: Marker | null = getMostRecentMarker();
      if (markerToShow) {
        marker = markerToShow;
        if (markerToShow.visible) {
          setShowMarker(true);
        } else {
          setShowMarker(false);
        }
      }
  }, [markerHistory]);

  // Show / Hide marker & initialize marker
  useEffect(() => {
    let myMarker = getMostRecentMarker();
    if (myMarker) { marker = myMarker }

    // Reposition marker
    positionMarker();

    let newMarker:Marker | undefined = undefined;

    // Create a new marker and store in history when we show the marker the first time
    if (!myMarker && showMarker && containerRef?.current) {

      // Get current element size
      let [currentWidth, currentHeight] = getCurrentSize(containerRef.current);

      // Store sketch object
      newMarker = {
        uuid: uuidv4(),
        viewerUuid: viewerUuid,
        color: viewer_color,
        created: new Date(),
        updated: new Date(),
        cam_index: cam_index,
        canvasWidth: currentWidth,
        canvasHeight: currentHeight,
        visible: showMarker,
        mEvents: [{
          x: currentWidth / 2,
          y: currentHeight / 2,
          count: 0
        }]
      }
      marker = newMarker
    }

    // Create stringified if marker needs updating
    let stringed;
    if (newMarker) {
      // Upload history to server
      stringed = JSON.stringify({
        sketches: [newMarker],
        action: 'addMarker',
      });
    } else if (myMarker && myMarker?.visible !== showMarker) {
      myMarker.visible = showMarker;
      stringed = JSON.stringify({
        sketches: [myMarker],
        action: 'toggleMarkerVisibility'
      })
    }

    // Send marker to server if we have data
    if (stringed) {
      (async () => {
        // Send to server
        try {
          let fetchOpts = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            credentials: "include",
            body: stringed,
          }
          let updateAt = dayjs();
          let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_history/' + viewerUuid, fetchOpts)
          if (res.ok) {
            let response = await res.json();
            // Update history
            if (response?.markers) {
              mergeMarkers(
                response.markers.filter((marker:Marker) => marker.cam_index === cam_index),
                false,
                updateAt
              );

              // Send call to update history
              if (sbPusher) {
                sbPusher.trigger('client-sb_get_sketches', "true");
              }
            }
          }
        } catch (e) {
          console.error('Network error sending mouse event history ', e);
        }
      })();
    }
  }, [showMarker]);

  // Actions
  const startTracking = (event:PointerEvent) => {
    // TODO: Track down how we get here without displayEl.current existing.
    // Don't move marker if we are sketching (canTrackMouse)
    if (
      !displayEl?.current ||
      !markerRef?.current
    ) { return }

    try {
      // Get Location - Remove 'px' suffix and convert to number
      let markerLeft:string = window
        .getComputedStyle(markerRef.current!)
        .getPropertyValue('left');
      let markerX:number = +markerLeft.substring(0, markerLeft.length - 2);

      let markerRight:string = window
        .getComputedStyle(markerRef.current!)
        .getPropertyValue('top')
      let markerY:number = +markerRight.substring(0, markerRight.length -2);

      // Get current element size
      let [currentWidth, currentHeight] = getCurrentSize(containerRef.current as Element);

      // Make sure we have a marker to update
      if (!marker) {
        marker = {
          uuid: uuidv4(),
          viewerUuid: viewerUuid,
          color: viewer_color,
          created: new Date(),
          updated: new Date(),
          cam_index: cam_index,
          canvasWidth: currentWidth,
          canvasHeight: currentHeight,
          visible: showMarker,
          mEvents: [{
            x: markerX,
            y: markerY,
            count: 0
          }]
        }
      }

      // Only track mouse events over the marker
      // The Svg is 24px x 16px with a -24px offset on X only
      // Add 6px in each direction so the tracking box is slightly larger than the icon
      if (
        displayEl?.current &&
        event.offsetX >= markerX - 30 &&
        event.offsetX <= markerX + 6 &&
        event.offsetY >= markerY - 6 &&
        event.offsetY <= markerY + 22
      ) {
        // Stop the page from moving on touch events
        displayEl.current!.style.touchAction = 'none'
        
        displayEl.current!.addEventListener('pointerup', stopTracking, listenerOpts);
        displayEl.current!.addEventListener('pointermove', trackCursor, listenerOpts);
        displayEl.current!.addEventListener('pointercancel', e => console.log('pointer cancel ', e), listenerOpts);
        
        // Begin sending data
        if (!sendTrigger) {
          sendTrigger = Number(setInterval(sendNewPath, sendInterval));
        }
      }

    } catch (e) {
      // fail silently?
      console.error('Could not move marker ', e);
    }
  }

  const trackCursor = async (event:PointerEvent) => {
    // Change cursor look
    document.body.style.cursor = "grabbing";
    
    // Get current element size
    let [currentWidth, currentHeight] = getCurrentSize(containerRef.current as Element);

    let newLeft:number = event.offsetX + 20; // Anchor to bottom left of center
    let newTop:number = event.offsetY - 10; // Anchor to bottom left of center

    // Don't move cursor outside of element
    if (newLeft > currentWidth) { newLeft = currentWidth} // Right limit
    if (newTop < 32) { newTop = 32 } // Top limit below menu bar
    if (newTop > currentHeight - 16) { newTop = currentHeight - 16} // Bottom limit

    let percentageX = (newLeft / currentWidth) * 100;
    let percentageY = (newTop / currentHeight) * 100

    // Update location
    let markerEl: HTMLElement = markerRef.current! as HTMLElement;
    if (markerEl.style.left !== percentageX + '%') {
      markerEl.style.left = percentageX + '%';
    }
    if (markerEl.style.top !== percentageY + '%') {
      markerEl.style.top = percentageY + '%';
    }

    // Store sketch object
    // Make sure we have a marker to update
    if (!marker) {
      marker = {
        uuid: uuidv4(),
        viewerUuid: viewerUuid,
        color: viewer_color,
        created: new Date(),
        updated: new Date(),
        cam_index: cam_index,
        canvasWidth: currentWidth,
        canvasHeight: currentHeight,
        visible: showMarker,
        mEvents: [{
          x: newLeft,
          y: newTop,
          count: 0
        }]
      }
    } else { // Update existing marker
      marker.updated = new Date();
      marker.canvasWidth = currentWidth;
      marker.canvasHeight = currentHeight;
      marker.mEvents = [{
        x: newLeft,
        y: newTop,
        count: 0
      }]
    }
  }

  const stopTracking = async (event:PointerEvent) => {
    // Reset cursor look
    document.body.style.cursor = "auto";

    // Stop tracking and start listening again for pointer clicks
    if (displayEl?.current) {
      listenerController.abort();
      setResetListener(!resetListener);

      // Allow page scrolling again
      if (displayEl?.current) {
        displayEl.current.style.touchAction = 'auto'
      }
    }

    // Get current element size
    let [currentWidth, currentHeight] = getCurrentSize(containerRef.current as Element);

    let newLeft = event.offsetX + 20;
    let newTop = event.offsetY - 10;

    // Don't move cursor outside of element
    if (newLeft > currentWidth) { newLeft = currentWidth} // Right limit
    if (newTop < 32) { newTop = 32 } // Top limit below menu bar
    if (newTop > currentHeight - 16) { newTop = currentHeight - 16} // Bottom limit
    
    let percentageX = (newLeft / currentWidth) * 100;
    let percentageY = (newTop / currentHeight) * 100

    // Update location
    let markerEl: HTMLElement = markerRef.current! as HTMLElement;
    if (markerEl.style.left !== percentageX + '%') {
      markerEl.style.left = percentageX + '%';
    }
    if (markerEl.style.top !== percentageY + '%') {
      markerEl.style.top = percentageY + '%';
    }

    // Allow page scrolling again
    if (displayEl?.current) {
      displayEl.current.style.touchAction = 'auto'
    }

    // Store sketch object
    // Make sure we have a marker to update
    let markerAction = 'updateMarker';
    if (!marker) {
      marker = {
        uuid: uuidv4(),
        viewerUuid: viewerUuid,
        color: viewer_color,
        created: new Date(),
        updated: new Date(),
        cam_index: cam_index,
        canvasWidth: currentWidth,
        canvasHeight: currentHeight,
        visible: showMarker,
        mEvents: [{
          x: newLeft,
          y: newTop,
          count: 0
        }]
      }
      markerAction = 'addMarker';
    } else {
      marker.updated = new Date();
      marker.canvasWidth = currentWidth;
      marker.canvasHeight = currentHeight;
      marker.mEvents = [{
        x: newLeft,
        y: newTop,
        count: 0
      }];
      marker.color = viewer_color;
    }

    // Send to remote clients
    sendNewPath();

    // Stop sending event updates
    clearInterval(sendTrigger);
    sendTrigger = undefined;

    // Send current drawing to server
    let stringed = JSON.stringify({
      sketches: [marker],
      action: markerAction
    });

    // Send to server
    try {
      let fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: stringed,
      }
      let updateAt = dayjs();
      let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_history/' + viewerUuid, fetchOpts)
      if (res.ok) {
        let response = await res.json();

        // Update history
        if (response?.markers) {
          mergeMarkers(
            response.markers.filter((marker:Marker) => marker.cam_index === cam_index),
            false,
            updateAt
          );

          // Send call to update history
          if (sbPusher) {
            sbPusher.trigger('client-sb_get_sketches', "true");
          }
        }
      }
    } catch (e) {
      console.error('Network error sending mouse event history ', e);
    }
  }

  // Send drawing to remote peers
  const sendNewPath = async () => {
    
    // Ensure we have a valid object to send
    if (marker?.viewerUuid) {
      // Send data
      if (sbPusher) {
        sbPusher.trigger('client-sb_marker_move', JSON.stringify(marker));
      }
    }
  }

  // Get most recent marker
  const getMostRecentMarker = (): Marker | null => {
    if (markerHistory?.length > 0) {
      let myMarker;
      let lastUpdated = dayjs(0);
      for (let i = markerHistory.length - 1; i >= 0 ; i--) {
        if (
          markerHistory[i].viewerUuid === viewerUuid &&
          markerHistory[i].cam_index === cam_index &&
          // markerHistory[i].visible &&
          dayjs(markerHistory[i].updated).diff(lastUpdated) > 0
        ) {
          myMarker = markerHistory[i];
          lastUpdated = dayjs(markerHistory[i].updated);
        }
      }
      return myMarker ? myMarker : null;
    } else { return null }
  }

  // Position marker in container
  const positionMarker = () => {
    let myMarker = getMostRecentMarker();

    // Update marker using history
    if (myMarker && markerRef?.current && containerRef?.current) {
      // Update location only if it differs

      let [currentWidth, currentHeight] = getCurrentSize(containerRef.current as Element);

      // Set marker boundaries
      let newLeft = myMarker.mEvents[0].x
      let newTop = myMarker.mEvents[0].y;
      if (newLeft > currentWidth) { newLeft = currentWidth} // Right Limit
      if (newTop < 32) { newTop = 32 } // Top limit below menu bar
      if (newTop > currentHeight - 16) { newTop = currentHeight - 16 } // Bottom Limit

      // Scale
      let scaleX = currentWidth / myMarker.canvasWidth;
      let scaleY = currentHeight / myMarker.canvasHeight;

      let percentageX = ((newLeft * scaleX) / currentWidth) * 100;
      let percentageY = ((newTop * scaleY) / currentHeight) * 100

      let markerEl: HTMLElement = markerRef.current! as HTMLElement;
      if (markerEl.style.left !== percentageX + '%') {
        markerEl.style.left = percentageX + '%';
      }
      if (markerEl.style.top !== percentageY + '%') {
        markerEl.style.top = percentageY + '%';
      }
    }
  }

  // Render
  return (
    <Grid
      className={classes.container}
      ref={containerRef}
    >
      <MarkerIcon
        markerRef={markerRef}
        viewer_color={viewer_color}
        showMarker={showMarker}
      />
    </Grid>
  );

}
