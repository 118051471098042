import React, { useState } from 'react';
import useDevices from '../../../hooks/useDevices/useDevices';
import {
Button,
} from '@mui/material/';
import DevicesIcon from '@mui/icons-material/Devices';
import DeviceChangeDialog from '../MenuBar/DeviceChangeDialog';

export default function DeviceChangeButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const { hasVideoInputDevices } = useDevices();

  return (
    <>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className={props.className}
        disabled={!hasVideoInputDevices || props.disabled}
        startIcon={<DevicesIcon />}
      >
        Change Devices
      </Button>
      <DeviceChangeDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );

}
