import React, { createContext, useContext, useState, useEffect } from 'react';
import { Participant, Room } from 'twilio-video';

type mutedParticipantsContextType = [Participant | null, (participant: Participant) => void];

export const mutedParticipantsContext = createContext<selectedParticipantContextType>(null!);

export default function useMutedParticipants() {
  const [mutedParticipants, setMutedParticipants] = useContext(mutedParticipantsContext);
  return [mutedParticipants, setMutedParticipants] as const;
}

type MutedParticipantsProviderProps = {
  room: Room;
  children: React.ReactNode;
};

export function MutedParticipantsProvider({ room, children }: MutedParticipantsProviderProps) {
  const [mutedParticipants, _setMutedParticipants] = useState<[Participant] | []>([]);
  const setMutedParticipants = (participant: Participant) => {
    if (mutedParticipants.includes(participant)) {
      // Remove participant (unmute) from array
      _setMutedParticipants(
        mutedParticipants.filter(parti => {
          return parti === participant ? false : true;
        })
      );
    } else {
      // Add participant (mute)
      _setMutedParticipants([
        ...mutedParticipants,
        participant
      ]);
    }
  };

  useEffect(() => {
    const onDisconnect = () => _setMutedParticipants([]);

    const handleParticipantDisconnected = (participant: Participant) =>
      _setMutedParticipants(
        mutedParticipants.filter(parti => {
          return parti === participant ? false : true;
        })
      );

    room.on('disconnected', onDisconnect);
    room.on('participantDisconnected', handleParticipantDisconnected);
    return () => {
      room.off('disconnected', onDisconnect);
      room.off('participantDisconnected', handleParticipantDisconnected);
    };
  }, [room]);

  return (
    <mutedParticipantsContext.Provider value={[mutedParticipants, setMutedParticipants]}>
      {children}
    </mutedParticipantsContext.Provider>
  );
}
