import React from 'react';
import { styled } from '@mui/styles';;
import { Participant } from 'twilio-video';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import useSubscriptionRules from '../../../hooks/useSubscriptionRules/useSubscriptionRules';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useIsTrackEnabled from '../../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useIsTrackSubscribed from '../../../hooks/usePublicationIsAudioTrackSubscribed/usePublicationIsAudioTrackSubscribed';
import useMutedParticipants from '../VideoProvider/useMutedParticipants/useMutedParticipants';



export default function MuteParticipant({ participant }: { participant: Participant }) {

  const [mutedParticipants, setMutedParticipants] = useMutedParticipants();

  const handleClick = () => {
    setMutedParticipants(participant);
  }

  return !mutedParticipants.includes(participant) ?
    <IconButton onClick={handleClick} size="large">
      <VolumeUpIcon
        color="primary"
      />
    </IconButton>
    :
    <IconButton onClick={handleClick} size="large">
      <VolumeOffIcon />
    </IconButton>;
}
