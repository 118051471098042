import React, { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Container, Grid } from '@mui/material';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

import WooUsersList from './admin_woo_users_list';
import SessionList from './admin_session_list';
import ActiveSessionList from './admin_active_session_list';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import cssClasses from '../css/app.module.css';
import PropTypes from 'prop-types';
import ContactsIcon from '@mui/icons-material/Contacts';
import MovieIcon from '@mui/icons-material/Movie';
import InfoIcon from '@mui/icons-material/Info';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Grid item>
        {children}
      </Grid>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class SiteAdmin extends Component {
  constructor(props) {
    super(props);

    let userName = "";
    let cookies = null;
    let selectedTab = 0;
    let parsedURL = window.location.pathname.split('/');
    try {
      if (parsedURL.length > 2 && parsedURL[2] !== "") {
          userName = parsedURL[2];
          cookies = Cookies.get(parsedURL[2]);
      }

      selectedTab = Number(localStorage.getItem(parsedURL[2] + '_tab'));

    } catch (e) {
      console.log('Could not load info ', e);
    }

    this.state = {
      userName: userName,
      cookies: cookies,
      message: [],
      selectedTab: selectedTab,
    }
  }

  Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://setstream.io" underline="hover">
          SetStream.io
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  // UI Actions
  changeTabs = (event, value) => {
    // Handle tab change
    console.log('TAB CHANGE ', value);
    this.setState({
      selectedTab: value
    });
    localStorage.setItem(this.state.userName + '_tab', value);
  }

  render() {

    return (
      <Box id="content" className={this.props.cssClasses.content}>
        <Grid container component="main" className={this.props.cssClasses.root}>
          <CssBaseline />
          <Container>

            <Tabs
              value = {this.state.selectedTab}
              onChange = {this.changeTabs}
              indicatorColor = "primary"
              textColor = "primary"
              centered
              fullWidth
              // variant = "scrollable"
            >
              <Tab label="Customer Lookup" icon={<ContactsIcon />} />
              {/* <Tab label="All Projects List" icon={<MovieIcon />} /> */}
              <Tab label="Currently Active Sessions" icon={<InfoIcon />} />
            </Tabs>


            <TabPanel
              label = "Customer Lookup"
              value={this.state.selectedTab}
              index={0}
            >
              <Grid item elevation={6} className={this.props.cssClasses.projectInfo}>
                <WooUsersList
                  userName={this.state.userName}
                  cssClasses={this.props.cssClasses}
                />
              </Grid>
            </TabPanel>

            {/* <TabPanel
              label = "All Projects List"
              value={this.state.selectedTab}
              index={1}
            >
              <Grid item elevation={6} className={this.props.cssClasses.projectInfo}>
                <SessionList userName={this.state.userName} cssClasses={this.props.cssClasses} />
              </Grid>

            </TabPanel> */}

            <TabPanel
              label = "Currently Active Sessions"
              value={this.state.selectedTab}
              index={2}
            >
              <ActiveSessionList userName={this.state.userName} cssClasses={this.props.cssClasses} />
            </TabPanel>

          </Container>
        </Grid>
      </Box>
    );
  }
}

export default withRouter((SiteAdmin))
