import React, { useEffect, useState } from 'react';
import { createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
Grid,
IconButton,
Tooltip,
} from '@mui/material/';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Crop169Icon from '@mui/icons-material/Crop169';
import CallMadeIcon from '@mui/icons-material/CallMade';
import RemoveIcon from '@mui/icons-material/Remove';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';
import { Sketch } from '../sketches_types';
import useSketches from '../Context/useSketches';
import { isTouchCapable } from '../../../utils/isTouchCapable';
import MarkerControlIcon from '../Marker/marker_control_icon';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  drawer: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    height: '33px',
    width: '100%',
    textAlign: 'center',
    // [theme.breakpoints.up('sm')]: {
    //   visibility:'collapse',
    // }
    pointerEvents: 'none',
  },
  controlsHidden: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    height: '33px',
    width: '100%',
    textAlign: 'center',
    visibility:'collapse',
    pointerEvents: 'none',
    paddingLeft: '96px',
    paddingRight: '96px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '66px'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  controlsShown: {
    position: 'absolute',
    zIndex: 20,
    top: 0,
    left: 0,
    height: '33px',
    width: '100%',
    textAlign: 'center',
    visibility:'visible',
    pointerEvents: 'none',
    paddingLeft: '96px',
    paddingRight: '96px',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '66px'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  controller: {
    borderRadius: '4px',
    pointerEvents: 'auto',
    [theme.breakpoints.down('sm')]: {
      background: `linear-gradient(
        0deg,
        rgba(21,21,21,0) 0%,
        rgba(21,21,21,0.6) 30%,
        rgba(21,21,21,1) 80%
      )`,
      boxShadow: '-4px 4px 12px -2px rgba(0,0,0,0.5)',
    }
  },
  item: {
  },
  control: {
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    width: '2px',
    backgroundColor: theme.palette.divider,
    margin: '8px 12px',
  }
}));

export default function SketchesControls(props:any) {
  // Hooks
  const classes = useStyles();
  
  const {
    showMarker,
    setShowMarker,
    showControls,
    setShowControls,
    sketchType,
    setSketchType,
    canTrackMouse,
    setCanTrackMouse,
    sketchHistory,
    allowDrawing,
    clearMySketches,
    clearAllSketches,
    cam_index,
    sbPusher,
    viewerUuid,
    setSketchHistory
  } = useSketches();

  // const [showControls, setShowControls] = useState<boolean>(false);

  useEffect(() => {
    if (
      sketchType === '' &&
      isTouchCapable &&
      props.showControls &&
      cam_index === props.selectedCam
    ) {
      setShowControls(true);
    // } else if (sketchType !== '' || (showMarker && showControls)) {
      // Ignore
    } else if (canTrackMouse && sketchType != '') {
      setShowControls(true);
    } else {
      setShowControls(false)
    }
  }, [props.showControls, props.selectedCam])

  useEffect(() => {
    if (showMarker && !showControls) {
      setShowControls(true);
    }
  }, [showMarker])

  const toggleDrawing = async (type:string) => {
    switch (type) {
      case 'marker':
        // Disable drawing when turning on marker
        if (
          !showMarker &&
          canTrackMouse
          ) {
            setSketchType('');
            setCanTrackMouse(false);
          }
          setShowMarker(!showMarker);
          console.log('MyMarker ', true);
        break;
      case 'clear_my':
        setSketchType('');
        setCanTrackMouse(false);
        clearMySketches();
        break;
      case 'clear_all':
        setShowMarker(false);
        setSketchType('');
        setCanTrackMouse(false);
        clearAllSketches();
        break;
      case 'sketch':
      case 'line':
      case 'box':
      default:
        if (sketchType === type) {
          // Turn off sketching
          setSketchType('');
          setCanTrackMouse(false);
        } else {
          // Turn on sketching
          setSketchType(type);
          setCanTrackMouse(true);
        }
      }
  }

  const undo = async (action:string) => {
    let mostRecent:Sketch | undefined;
    if (action === 'undo') {
      for (let i = sketchHistory.length - 1; i > 0; i--) {
        if (
          sketchHistory[i].viewerUuid === viewerUuid &&
          sketchHistory[i].sketchType !== 'marker' &&
          !sketchHistory[i].undone
        ) {
          mostRecent = sketchHistory[i];
          break;
        }
      }
    } else {
      for (let i = sketchHistory.length - 1; i > 0; i--) {
        if (
          sketchHistory[i].viewerUuid === viewerUuid &&
          sketchHistory[i].sketchType !== 'marker' &&
          sketchHistory[i].undone
        ) {
          mostRecent = sketchHistory[i];
          break;
        }
      }
    }
    if (!mostRecent) return;

    mostRecent.undone = !mostRecent.undone;

    // Update local drawing
    let sketches = [...sketchHistory];
    let mostRecentIndex = sketches.indexOf(mostRecent);

    sketches[mostRecentIndex].undone = mostRecent.undone;
    setSketchHistory(sketches);

    try {
      let fetchOpts = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify({
          action: 'toggleUndo',
          sketches: [mostRecent]
        }),
      }
      let res = await fetch(process.env.REACT_APP_API_URL + '/api/sketches_history/' + viewerUuid, fetchOpts);
      if (res.ok) {
        // let response = await res.json();

        // Send call to update history
        if (sbPusher) {
          sbPusher.trigger('client-sb_get_sketches', "true");
        }
      }
    } catch (e) {
      console.error('Network error on Sketches undo ', e);
    }
  }

  // Render
  return (
    <Grid
      container
      id="sketches_controls"
      className={
        showControls ? classes.controlsShown : classes.controlsHidden
      }
    >

      <Grid item style={{flex:1}}></Grid>

      <Grid item>
        <Grid container className={classes.controller}>
          <Grid item className={classes.item}>
            <Tooltip title="Sketch" placement="bottom">
              <IconButton
                onClick={() => toggleDrawing('sketch')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <CreateIcon
                  color={sketchType === 'sketch' ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.item}>
            <Tooltip title="Line" placement="bottom">
              <IconButton
                onClick={() => toggleDrawing('line')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <RemoveIcon
                  color={sketchType === 'line' ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.item}>
            <Tooltip title="Box" placement="bottom">
              <IconButton
                onClick={() => toggleDrawing('box')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <Crop169Icon
                  color={sketchType === 'box' ? "primary" : "inherit"}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.item}>
            <Tooltip title="New Drawing" placement="bottom">
              <IconButton
                onClick={() => toggleDrawing('clear_my')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.divider}></Grid>

          <Grid item className={classes.item}>
            <Tooltip title="Pointer" placement="bottom">
              <IconButton
                onClick={() => toggleDrawing('marker')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <MarkerControlIcon 
                  viewer_color={showMarker ? "#238e4d" : "#F3F3F3"}
                  showMarker={true}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.divider}></Grid>

          <Grid item className={classes.item}>
            <Tooltip title="Undo" placement="bottom">
              <IconButton
                onClick={() => undo('undo')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.item}>
            <Tooltip title="Redo" placement="bottom">
              <IconButton
                onClick={()=> undo('redo')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <UpdateIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.divider}></Grid>

          <Grid item className={classes.item}>
            <Tooltip title="Clear ALL" placement="bottom">
              <IconButton
                onClick={() => toggleDrawing('clear_all')}
                className={classes.control}
                disabled={!allowDrawing}
                size="large">
                <BackspaceIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{flex:1}}></Grid>

    </Grid>
  );
}
