import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import AvatarIcon from '../../../icons/AvatarIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PinIcon from '../PinIcon/PinIcon';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import Grid from '@mui/material/Grid';

import useIsTrackSwitchedOff from '../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../../hooks/usePublications/usePublications';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useTrack from '../../../hooks/useTrack/useTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useParticipantIsReconnecting from '../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import MuteParticipant from '../MuteParticipant/MuteParticipant';
import ScreenFull from 'screenfull';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '0.1em 0.3em 0.1em 0',
    display: 'inline-flex',
    '& svg': {
      marginLeft: '0.3em',
    },
    position:'absolute',
    bottom:0,
    left:0,
    width: '100%',
    whiteSpace: 'nowrap'
  },
  muteParticipantContainer: {
    display:'flex',
    width: '28px',
    height: '28px',
    position: 'absolute',
    right: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 7,
    height: '100%',
    width: '100%',
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(40, 42, 43, 0.75)',
    zIndex: 6,
  },
  fullWidth: {
    height: '100%',
    overflow: 'hidden',
    // gridArea: '1 / 1 / 2 / 3',
    [theme.breakpoints.down('md')]: {
      // gridArea: '1 / 1 / 3 / 3',
    },
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'black',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 6,
    '& svg': {
      transform: 'scale(2)',
    },
  },
  overlayIconContainer: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    width: '28px',
    height: '28px',
    borderRadius: '8px'
  },
  overlayIcons: {
    width: '28px',
    height: '28px',
    padding:0,
    color: 'white'
  }
}));

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isSelected?: boolean;
  setSelectedParticipant?: () => void;
}

export default function MainParticipantInfo({
  participant,
  children,
  isSelected,
  setSelectedParticipant
}: MainParticipantInfoProps) {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const isLocalParticipant = localParticipant === participant;

  const screenShareParticipant = useScreenShareParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;

  const publications = usePublications(participant);
  const videoPublication = publications.find(p => p.trackName.includes('camera'));
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoEnabled = Boolean(videoTrack);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const toggleFullScreen = () => {
    let elem = document.getElementById('main-participant')
    if (elem) {
      ScreenFull.toggle(elem)
    }
  }

  return (
    <div
      data-cy-main-participant
      data-cy-participant={participant.identity}
      className={clsx(classes.container, {
        [classes.fullWidth]: !isRemoteParticipantScreenSharing,
      })}
      id="main-participant"
    >
      <div className={classes.infoContainer}>
        <Grid container>

          {
            !isLocalParticipant &&
            !isMobile &&
            isSelected &&
            <Grid item className={classes.overlayIconContainer}>
              <IconButton
                onClick={() => setSelectedParticipant(participant)}
                className={classes.overlayIcons}
                size="large">
                <PinIcon
                  isPinned={isSelected}
                />
              </IconButton>
            </Grid>
          }


          <Grid item style={{flex: 1}}></Grid>

          {
            !isLocalParticipant &&
            ScreenFull.isEnabled &&
            <Grid item className={classes.overlayIconContainer}>
              <IconButton onClick={toggleFullScreen} className={classes.overlayIcons} size="large">
                <AspectRatioIcon />
              </IconButton>
            </Grid>
          }



          {/* !isLocalParticipant &&
            <Grid item className={classes.overlayIconContainer}>
              <MuteParticipant
                participant={participant}
                className={classes.overlayIcons}
              />
            </Grid>
          */}

        </Grid>

        <div className={classes.identity}>
          <AudioLevelIndicator audioTrack={audioTrack} />
          <Typography variant="body1" color="inherit">
            {participant.identity}
            {isLocalParticipant && ' (You)'}
            {screenSharePublication && ' - Screen'}
          </Typography>
        </div>
      </div>
      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className={classes.avatarContainer}>
          <AvatarIcon />
        </div>
      )}
      {isParticipantReconnecting && (
        <div className={classes.reconnectingContainer}>
          <Typography variant="body1" style={{ color: 'white' }}>
            Reconnecting...
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
}
