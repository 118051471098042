import { Room, TwilioError } from 'twilio-video';
import { useEffect } from 'react';
import logToServer from '../../../../utils/log_to_server';
import { Callback } from '../../../types';

export default function useHandleRoomDisconnectionErrors(room: Room, onError: Callback) {
  useEffect(() => {
    const onDisconnected = (room: Room, error: TwilioError) => {
      if (error) {
        onError(error);
        logToServer({
          section: 'Twilio',
          action: 'Room Disconnection Error',
          page: window.location.href,
          message: error?.message,
          eName: error?.name,
          eStack: error?.stack
        });
      }
    };

    room.on('disconnected', onDisconnected);
    return () => {
      room.off('disconnected', onDisconnected);
    };
  }, [room, onError]);
}
