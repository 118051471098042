import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import IconButton from '@mui/material/IconButton';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import AvatarIcon from '../../../icons/AvatarIcon';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from '../PinIcon/PinIcon';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';
import Typography from '@mui/material/Typography';
import useDominantSpeaker from '../../../hooks/useDominantSpeaker/useDominantSpeaker';

import useIsTrackSwitchedOff from '../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import { useAppState } from '../../../state';
import MuteParticipant from '../MuteParticipant/MuteParticipant';
import { isMobile } from '../../../utils';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    identity: {
      background: 'rgba(58,58,58,.9)',
      color: 'white',
      padding: '4px 8px',
      margin: '4px 0px',
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
      borderRadius: '4px'
    },
    typeography: {
      color: 'white',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
      },
    },
  })
);

interface ParticipantInfoProps {
  participant: Participant;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  isSelected,
  isLocalParticipant,
}: ParticipantInfoProps) {
  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const dominantSpeaker = useDominantSpeaker();
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const classes = useStyles();

  return (
    <Grid item className={classes.identity} style={{border: participant === dominantSpeaker ? '2px solid #47b969' : '2px solid rgba(58,58,58,.9)'}}>
      <AudioLevelIndicator audioTrack={audioTrack} />
      <Typography variant="body1" className={classes.typeography} component="span">
        {!isLocalParticipant && participant.identity}
        {isLocalParticipant && ' (You)'}
        {isParticipantReconnecting && ' (Reconnecting...)'}
      </Typography>
      {
        !isLocalParticipant &&
        <Grid item className={classes.overlayIconContainer}>
          <MuteParticipant
            participant={participant}
            className={classes.overlayIcons}
          />
        </Grid>
      }
    </Grid>

  );
}
